import React, { Component } from 'react';
import axios from 'axios';

import * as routes from '../routes';

export default class Search extends Component{

	constructor(props){
		super(props);

		this.state = {
			searchResults1: null,
			searchResults2: null
		}
	}

	searchTrainings = () => {
		axios.post(`${routes.DOMAIN}/v2/search.php`, {
			action: 'searchTrainings',
			jwt: this.props.cookies.get('tk'),
			search_string: this.refs.training_search.value
		})
			.then((result) => {
				//console.log(result);
				this.setState({
					searchResults1: result.data
				})
			})
	}

	searchTags = () => {
		axios.post(`${routes.DOMAIN}/v2/search.php`, {
			action: 'searchTags',
			jwt: this.props.cookies.get('tk'),
			search_string: this.refs.tag_search.value
		})
			.then((result) => {
				//console.log(result);
				this.setState({
					searchResults2: result.data
				})
			})
	}

	render(){
		return(
			<div>
				<form>
					<h3>Trainings search</h3>
					<input ref='training_search' type='text' onChange={this.searchTrainings}/>
				</form>

				{this.state.searchResults1 ? this.state.searchResults1.map((result, index) => {
					return(
						<div key={index}>
							<h4>{result.title}</h4>
							<p>{result.description}</p>
						</div>
					)
				}) : null}

				<form>
					<h3>Tag Search</h3>
					<input ref='tag_search' type='text' onChange={this.searchTags}/>
				</form>

				
				{this.state.searchResults2 ? this.state.searchResults2.map((result, index) => {
					return(
						<div key={index}>
							<h4>{result.title}</h4>
							<p>{result.description}</p>
						</div>
					)
				}) : null}
			</div>
		);
	}
}