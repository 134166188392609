import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/sl';
import StarRatings from 'react-star-ratings';
import axios from 'axios';
import Textarea from 'react-textarea-autosize';
import { isSafari } from 'react-device-detect';

import * as routes from '../routes';

import { popupMessage } from '../components/PopupMessage';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TagEntry from '../components/TagEntry';
import TopHeader from '../components/TopHeader';
import SidebarTrainingEntry from '../components/SidebarTrainingEntry';
import ProgressBar from '../components/ProgressBar';

moment.locale('sl');

export default class AddTraining extends Component {

	constructor(props) {
		super(props);

		this.state = {
			training: null,
			tags: [],
			filteredTags: null,
			checked: false,
			new_img: 0,
			redirect: false
		}
	}

	getTrainings = () => {
		axios.post(`${routes.DOMAIN}/v2/trainings.php`, {
			action: 'getTrainings',
			jwt: this.props.cookies.get('tk')
		})
			.then((result) => {
				//console.log(result);
				this.setState({
					trainings: result.data
				})
			})
	}

	getAllTags = () => {
		axios.post(`${routes.DOMAIN}/v2/tags.php`, {
			action: 'getTags',
			jwt: this.props.cookies.get('tk')
		})
			.then((result) => {
				//console.log(result.data);

				this.setState({
					allTags: result.data
				}, () => this.removeSelectedTags())
			})
	}

	removeSelectedTags = (search = false) => {
		let selectedTags = this.state.tags.map(selectedTag => { return selectedTag.title; });
		let filteredTags = this.state.allTags.filter(tag => !selectedTags.includes(tag.title));

		if (search) {
			filteredTags = this.state.tagSearchResults.filter(tag => !selectedTags.includes(tag.title));
		}

		this.setState({
			filteredTags: filteredTags
		})
	}

	searchTags = () => {
		axios.post(`${routes.DOMAIN}/v2/search.php`, {
			action: 'searchTags',
			jwt: this.props.cookies.get('tk'),
			search_string: this.refs.input_tag.value
		})
			.then((result) => {
				//console.log(result);
				this.setState({
					tagSearchResults: result.data
				}, () => this.removeSelectedTags(true))
			})
	}

	handleAddTraining = (event) => {

		event.preventDefault();

		var header_url = this.state.header_picture_url ? this.state.header_picture_url : '/assets/img/default.jpg';

		let formData = new FormData();

		formData.append('action', 'addTraining');
		formData.append('environment', process.env.NODE_ENV);
		formData.append('nodecode', true);
		formData.append('jwt', this.props.cookies.get('tk'));
		formData.append('user_id', this.props.session.user_id);
		formData.append('training_title', this.refs.training_title.value);
		formData.append('training_description', this.training_descrption.value);
		formData.append('header_picture', header_url)
		formData.append('num', this.state.new_img);

		for (var i = 0; i < this.state.new_img; i++) {
			formData.append(`image_url_${i}`, this.state[`new_image_url_${i}`]);
			formData.append(`image_description_${i}`, this[`new_image_description_${i}`].value);
			formData.append(`image_rate_${i}`, this.state[`new_rating_${i}`] || 0);
		}

		axios.post(`${routes.DOMAIN}/v2/trainings.php`, formData)
			.then((result) => {
				//console.log(result.data);
				if (result.data != false && result.data != 409) {
					this.state.tags.map((tag) => {
						this.addTag(tag.title, result.data);
					})

					this.setState({
						new_training_id: result.data,
						redirect: true
					})

					popupMessage({
						type: 'success',
						message: 'Trening uspešno dodan!'
					})
				} else {
					popupMessage({
						type: 'error',
						message: 'Napaka pri dodajanju treninga!'
					})
				}
			})
	}

	newImage = () => {
		this.setState({
			new_img: this.state.new_img + 1
		})
	}

	uploadImage = (type, index) => {
		var imageName = type === "new" ? `new_image_url_${index}` : `header_picture_url`;
		var errorName = type === "new" ? `new_image_error_${index}` : `header_picture_error`;
		var uploadProgress = type === "new" ? `new_picture_${index}_progress` : 'header_picture_progress';
		var totalSize = type === "new" ? `new_picture_${index}_size` : 'header_picture_size';
		let formData = new FormData();

		formData.append('action', 'uploadImage');
		formData.append('environment', process.env.NODE_ENV);
		formData.append('nodecode', true);
		formData.append('jwt', this.props.cookies.get('tk'));
		formData.append('rotate_deg', 0);
		switch (type) {
			case "new":
				formData.append(`new_image`, this.refs[`new_image_${index}`].files[0]);
				var rotate = this.state[`new_image_${index}_rotation`] != null ? this.state[`new_image_${index}_rotation`] + 90 : 0;
				formData.append('rotate_deg', rotate);
				this.setState({
					[`new_image_${index}_rotation`]: rotate
				})
				break;
			case "header":
				formData.append(`new_image`, this.refs[`header_picture`].files[0]);
				var rotate = this.state[`header_img_rotation`] != null ? this.state[`header_img_rotation`] + 90 : 0;
				formData.append('rotate_deg', rotate);
				this.setState({
					[`header_img_rotation`]: rotate
				})
				break;
			default:
				break;
		}

		const config = {
			onUploadProgress: (progressEvent) => {
				this.setState({
					[uploadProgress]: progressEvent.loaded,
					[totalSize]: progressEvent.total
				})
			}
		}

		axios.post(`${routes.DOMAIN}/v2/trainings.php`, formData, config)
			.then((result) => {
				if ((result.data).startsWith("/assets/img")) {
					this.setState({
						[imageName]: result.data,
						[errorName]: null
					})
				} else {
					this.setState({
						[imageName]: null,
						[errorName]: result.data
					})
					popupMessage({
						type: 'error',
						message: 'Napaka pri nalaganju slike!'
					})
				}
			})
	}

	addTag = (tag_name, training_id) => {
		axios.post(`${routes.DOMAIN}/v2/tags.php`, {
			action: 'addTag',
			jwt: this.props.cookies.get('tk'),
			training_id: training_id,
			input_tag: tag_name
		})
			.then((result) => {
				//var [r] = result.data;
				//console.log(result.data);
			})
	}

	addGhostTag = (title) => {
		var arr = this.state.tags;
		arr.push({ title: title });

		this.setState({
			tags: arr,
			isAddingTag: false,
			tagSearchResults: null
		})
	}

	removeGhostTag = (title) => {
		var arr = this.state.tags;
		var pos = arr.findIndex(tag => tag.title === title);
		arr.splice(pos, 1);

		this.setState({
			tags: arr
		})
	}

	changeNewRating = (rating, name) => {
		this.setState({
			[name]: rating
		})
	}

	componentWillMount() {
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		this.getTrainings();
		this.getAllTags();
	}

	render() {

		const image_dom = [];

		for (var i = 0; i < this.state.new_img; i++) {
			var varNewName = `new_rating_${i}`;
			var descName = `new_image_description_${i}`;
			var index = i;

			image_dom.push(
				<div key={i} style={{ position: 'relative', marginBottom: 20 }}>
					<input ref={`new_image_${i}`} type="file" id={`new_img_upload_${i}`} style={{ display: 'none' }} onInput={() => this.uploadImage("new", index)} onChange={() => isSafari ? this.uploadImage("new", index) : null} />
					<label htmlFor={`new_img_upload_${i}`} style={{ backgroundImage: `url('${this.state[`new_image_url_${index}`] ? this.state[`new_image_url_${index}`] : "none"}')` }} className='image-upload-area'>
						<div className={"image-upload-area-inner " + (this.state[`new_image_url_${index}`] ? "change_message" : null)}>
							<React.Fragment>
								<span><svg xmlns="http://www.w3.org/2000/svg" className='image-icon' viewBox="0 0 32 32" version="1.1" width="64px" height="64px"><g id="surface1"><path d="M 2 5 L 2 27 L 30 27 L 30 5 Z M 4 7 L 28 7 L 28 20.90625 L 22.71875 15.59375 L 22 14.875 L 17.46875 19.40625 L 11.71875 13.59375 L 11 12.875 L 4 19.875 Z M 24 9 C 22.894531 9 22 9.894531 22 11 C 22 12.105469 22.894531 13 24 13 C 25.105469 13 26 12.105469 26 11 C 26 9.894531 25.105469 9 24 9 Z M 	11 15.71875 L 20.1875 25 L 4 25 L 4 22.71875 Z M 22 17.71875 L 28 23.71875 L 28 25 L 23.03125 25 L 18.875 20.8125 Z " /></g></svg></span>
								{this.state[`new_image_error_${index}`] !== "Izberite sliko" ? <span>{this.state[`new_image_url_${index}`] ? "Zamenjaj sliko" : "Naloži sliko"}</span> : null}
							</React.Fragment>
							{this.state[`new_image_error_${index}`] ? <span style={{ color: '#e80000' }}>{this.state[`new_image_error_${index}`]}</span> : null}
						</div>
					</label>

					{this.state[`new_picture_${index}_size`] != null ?
						<div className='rotateIcon-wrapper' onClick={() => this.uploadImage("new", index)}>
							<img className='rotateIcon' src='/assets/img/rotate.svg' height={32} alt='Rotate' />
						</div>
						: null
					}

					<ProgressBar progress={this.state[`new_picture_${index}_progress`]} size={this.state[`new_picture_${index}_size`]} error={this.state[`new_image_error_${index}`]} />

					<div className='image-info'>
						<h4 style={{ textTransform: 'capitalize' }}>{moment().format('LLLL')}</h4>
						<StarRatings rating={this.state[varNewName] ? this.state[varNewName] : 0} changeRating={this.changeNewRating} starDimension="24px" starRatedColor="#ffdf88" numberOfStars={5} name={`new_rating_${i}`} />
					</div>
					<Textarea inputRef={textarea => (this[descName] = textarea)} className='custom-input new-image'></Textarea>
					{this.state.new_img - 1 === i ? <button type='button' style={{ padding: "6px 10px" }} className='btn-accent-fill-ghost' onClick={() => this.setState({ new_img: this.state.new_img - 1 })}>Izbriši</button> : null}
				</div>)
		}

		return (
			<div className="animsition">
				{/* Preloader Start Here */}
				{this.state.redirect == true ? <Redirect to={`${routes.defaultTraining}/${this.state.new_training_id}`} /> : null}
				<div className="click-capture" />
				{/* Preloader End Here */}
				<div id="wrapper" className="wrapper wrp-top boxed-full light-dark">
					<Header {...this.props} />
					<TopHeader config={{ title: 'Nov Trening' }} />
					{/* Class Details Page Area Start Here */}
					<div className="class-details-area body-bg section-space-all">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-7 col-xs-12">
									<div className="class-details-inner">
										<div className="class-details-inner-top">
											<div className="class-img-holder">
												<input ref='header_picture' type="file" id='header_picture_upload' style={{ display: 'none' }} onInput={() => this.uploadImage("header")} onChange={() => isSafari ? this.uploadImage("header") : null} />
												<label htmlFor='header_picture_upload' style={{ backgroundImage: `url('${this.state.header_picture_url ? this.state.header_picture_url : "none"}')` }} className='image-upload-area'>
													<div className={"image-upload-area-inner " + (this.state.header_picture_url ? "change_message" : null)}>
														<React.Fragment>
															<span><svg xmlns="http://www.w3.org/2000/svg" className='image-icon' viewBox="0 0 32 32" version="1.1" width="64px" height="64px"><g id="surface1"><path d="M 2 5 L 2 27 L 30 27 L 30 5 Z M 4 7 L 28 7 L 28 20.90625 L 22.71875 15.59375 L 22 14.875 L 17.46875 19.40625 L 11.71875 13.59375 L 11 12.875 L 4 19.875 Z M 24 9 C 22.894531 9 22 9.894531 22 11 C 22 12.105469 22.894531 13 24 13 C 25.105469 13 26 12.105469 26 11 C 26 9.894531 25.105469 9 24 9 Z M 	11 15.71875 L 20.1875 25 L 4 25 L 4 22.71875 Z M 22 17.71875 L 28 23.71875 L 28 25 L 23.03125 25 L 18.875 20.8125 Z " /></g></svg></span>
															{this.state.header_picture_url !== "Izberite sliko" ? <span>{this.state.header_picture_url ? "Zamenjaj naslovno sliko" : "Naloži naslovno sliko"}</span> : null}
														</React.Fragment>
														{this.state.header_picutre_error ? <span style={{ color: '#e80000' }}>{this.state.header_picutre_error}</span> : null}
													</div>
												</label>

												{this.state.header_picture_size != null ?
													<div className='rotateIcon-wrapper' onClick={() => this.uploadImage("header")}>
														<img className='rotateIcon' src='/assets/img/rotate.svg' height={32} alt='Rotate' />
													</div>
													: null
												}

												<ProgressBar progress={this.state.header_picture_progress} size={this.state.header_picture_size} error={this.state.header_picture_error} />
											</div>
											<div className="class-details-top-content">

												<form>
													<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
														<h2 style={{ marginBottom: 0 }}><input ref='training_title' style={{ marginTop: 0 }} className='custom-input' type='text' placeholder='Naslov' /></h2>
													</div>

													<ul className="class-comments">
														<li><i className="fa fa-calendar-minus-o" aria-hidden="true" />Zadnja posodobitev : <span>{moment().format('ll')}</span></li>
														<li><i className="fa fa-user" aria-hidden="true" />Trener :<span> {this.props.session.email}</span></li>
													</ul>

													<p className="mb-40">
														<Textarea inputRef={textarea => (this.training_descrption = textarea)} className='custom-input image-description'></Textarea>
													</p>

													{image_dom}

													<button type='button' className='btn-checkout' onClick={this.newImage}>Dodaj sliko</button><br /><br />

													<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
														<button type='submit' style={{ height: 'max-content', marginRight: 10 }} className='btn-checkout' onClick={this.handleAddTraining}>Dodaj</button>
														<Link to='/' style={{ height: 'max-content' }} className='btn-accent-fill-ghost'>Prekliči</Link>
													</div>

												</form>


											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-4 col-md-4 col-sm-5 col-xs-12">
									<div className="thegym-sidebar">

										<div className="sidebar-box">
											<div className="product-tags">
												<h3 className="title-sidebar">Kategorije</h3>
												<ul className='tag-list'>
													{this.state.tags ? this.state.tags.map((tag, index) => {
														if (Object.keys(tag).length !== 0) {
															return (
																<TagEntry key={index} tag={tag} {...this.props} refreshTags={() => this.getTraining()} new={true} removeGhostTag={this.removeGhostTag} />
															)
														} else {
															return (null)
														}
													}) : null}

												</ul>

												{!this.state.isAddingTag ?
													<li className='noPlus'><button type='button' onClick={() => this.setState({ isAddingTag: true })}>+</button></li>
													: (
														<div className='sidebar-search-area'>
															<div style={{ width: 259 }} className="input-group stylish-input-group">
																<input ref='input_tag' type="text" className="form-control tagSearchBar" placeholder="Ime kategorije" onChange={this.searchTags} />
																<span className="input-group-addon tagSearchButton">
																	<button type="submit" onClick={() => this.addGhostTag(this.refs.input_tag.value)}>
																		Dodaj
																	</button>
																</span>
															</div>
															{this.state.tagSearchResults ?
																<ul>
																	{this.state.filteredTags.map((tag, index) => {
																		return (<li key={index} className='searchResultTags' onClick={() => { this.refs.input_tag.value = tag.title; this.setState({ tagSearchResults: null }) }}>{tag.title}</li>)
																	})}
																</ul> : null}
														</div>
													)
												}
											</div>

											<div className="sidebar-box">
												<div className="popular-post">
													<h3 className="title-sidebar">Drugi Treningi</h3>

													<div className="tab-content">
														<div className="popular-post tab-pane fade active in" id="Popular">
															{this.state.trainings ? this.state.trainings.slice(0, 4).map((training) => {
																return (
																	<SidebarTrainingEntry key={training.id} training={training} />
																);
															}) : null}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}
