import React, { Component } from 'react';
import moment from 'moment';
import Textarea from 'react-textarea-autosize';
import axios from 'axios';

import * as routes from '../routes';
import { statusMessage } from './StatusMessage';
import { popupMessage } from './PopupMessage';
import AdminIcon from './AdminIcon';

export default class Comment extends Component {

	constructor(props){
		super(props);

		this.state = {
			isEditingComment: false,
			showMessage: false,
			messageConfig: {type: 'confirm'}
		}
	}

	editComment = () => {
		axios.post(`${routes.DOMAIN}/v2/comments.php`, {
			action: 'editComment',
			jwt: this.props.cookies.get('tk'),
			comment_id: this.props.comment.id,
			comment_new_content: this.new_content.value
		})
			.then((result) => {
				//console.log(result);
				if(result.data != false){
					this.setState({
						isEditingComment: false
					})
					this.props.getComments(this.props.match.params.id);
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri shranjevanju komentarja.',
					});
				}
			})
	}

	deleteComment = () => {
		axios.post(`${routes.DOMAIN}/v2/comments.php`, {
			action: 'deleteComment',
			jwt: this.props.cookies.get('tk'),
			comment_id: this.props.comment.id,
		})
			.then((result) => {
				//console.log(result);
				if(result.data != false){
					this.setState({
						isEditingComment: false
					})
					this.props.getComments(this.props.match.params.id);
					this.popupSuccessMessage();
				}else{
					this.popupErrorMessage();
				}
			})
	}

	warnDelete = () => {
		statusMessage({
			title: 'Opozorilo',
			message: 'Ali ste prepričani da želite izbrisati komentar?',
			buttons: [
				{
					label: 'Da',
					style: 'primary',
					onClick: () => this.deleteComment()
				},
				{
					label: 'Ne'
				}
			]
		});
	};
	
	popupSuccessMessage = () => {
		popupMessage({
			type: 'success',
			message: 'Komentar uspešno izbrisan.',
		});
	}

	popupErrorMessage = () => {
		popupMessage({
			type: 'error',
			message: 'Napaka pri brisanju komentarja.',
		});
	}


	render() {

		const comment = this.props.comment;
		const isOwner = this.props.session.user_id === comment.user_id  || this.props.session.accessLevel === 'administrator' ? true : false;
		
		const ConfirmButton = () => (<a onClick={this.editComment}><i style={{right: 100}} className="fa fa-check" aria-hidden="true"></i></a>);
		const CancelButton = () => (<a onClick={() => this.setState({ isEditingComment: false})}><i className="fa fa-times" aria-hidden="true"></i></a>);
		const DeleteButton = () => (<a onClick={this.warnDelete}><i style={{right: 50}} className="fa fa-trash" aria-hidden="true"></i></a>);

		return (
			<div className="media">
				<a href="#" className="pull-left">
					<img alt="Comments" src={comment.avatar} className="media-object"/>
				</a>
				
				<div className="media-body">
					<h3><a href="#">{comment.first_name + " " + comment.last_name}</a>{comment.access_level === 'administrator' ? <AdminIcon/> : null}</h3>
					<span>{moment(comment.date_add).format('LLL')}</span>
					{isOwner ? this.state.isEditingComment ? <Textarea inputRef={textarea => (this.new_content = textarea)} defaultValue={comment.content}></Textarea> : <p>{comment.content}</p> : <p>{comment.content}</p>}
					{isOwner ? this.state.isEditingComment ? <React.Fragment> <ConfirmButton/> <CancelButton/> <DeleteButton/> </React.Fragment> : <a onClick={() => this.setState({ isEditingComment: true})}><i className="fa fa-pencil" aria-hidden="true"></i></a> : null}
				</div>
			</div>
		)
	}
}
