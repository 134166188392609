import React, { Component } from 'react'

export default class DetailsLoading extends Component {

	render() {
		return (
			<div className="class-details-inner-top">
				<div className="class-img-holder">
					<div style={{height: 437, width: '100%'}} className='ghost-background'></div>
				</div>

				<div className="class-details-top-content">
					<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20}}>
						<h2 style={{marginBottom: 0, height: 42, width: 240}} className='ghost-background'></h2>
					</div>

					<ul className="class-comments">
						<li style={{height: 24, width: '100%'}} className='ghost-background'></li>
					</ul>

					<p style={{width: '100%', height: 200}} className="mb-40 ghost-background">
					</p>

					<div className='mb-40'>
						<span></span>

						<div className='image-info'>
							<h4 style={{textTransform: 'capitalize'}}></h4>
							<span></span>
						</div>

						<p></p>
					</div>
				</div>
			</div>
		)

}
}
