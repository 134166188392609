import React, { Component } from 'react';
import axios from 'axios';
import {isSafari} from 'react-device-detect';

import * as routes from '../routes';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LoadingPage from './LoadingPage';
import TopHeader from '../components/TopHeader';
import { popupMessage } from '../components/PopupMessage';
import ProgressBar from '../components/ProgressBar';


export default class UserProfile extends Component{

	constructor(props){
		super(props);

		this.state = {
			user: null,
			isEditingUser: false,
			isUpdatingPassword: false,
			isValid: false
		}
	}

	getUserDetails = () => {
		axios.post(`${routes.DOMAIN}/v2/users.php`, {
			action: 'getUserDetails',
			jwt: this.props.cookies.get('tk'),
		})
			.then((result) => {
				//console.log(result);
				var [r] = result.data
				this.setState({
					user: r
				})
			})
	}

	updateUserDetails = () => {
		axios.post(`${routes.DOMAIN}/v2/users.php`, {
			action: 'editUser',
			jwt: this.props.cookies.get('tk'),
			new_first_name: this.refs.new_first_name.value,
			new_last_name: this.refs.new_last_name.value,
			new_email: this.refs.new_email.value,
			new_picture: this.state.profile_picture_url ? this.state.profile_picture_url : this.state.user.avatar
		})
			.then((result) => {
				if(result.data == true){
					this.setState({
						isEditingUser: false
					});
					
					popupMessage({
						type: 'success',
						message: 'Shranjevanje uspešno'
					})
					this.getUserDetails();
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri shranjevanju!'
					})
				}
			});
	}

	updateUserPassword = () => {
		if(this.refs.new_password.value !== ""){
			axios.post(`${routes.DOMAIN}/v2/users.php`, {
				action: 'changePassword',
				jwt: this.props.cookies.get('tk'),
				id: this.state.user.id,
				current_password: this.refs.current_password.value,
				new_password: this.refs.new_password.value
			})
				.then((result) => {

					if(result.data == true){
						this.setState({
							isUpdatingPassword: false
						});
						popupMessage({
							type: 'success',
							message: 'Sprememba gesla uspešna'
						})
					}else{
						popupMessage({
							type: 'error',
							message: 'Napaka pri spreminjanju gesla!'
						})
					}
				})
		}
	}

	checkMatchingPassword = () => {
		if(this.refs.new_password.value === this.refs.new_password_confirm.value){
			this.setState({
				isValid: true
			})
		}else{
			this.setState({
				isValid: false
			})
		}
	}

	uploadImage = () => {
		var imageName = `profile_picture_url`;
		var errorName = `profile_picture_error`;
		var uploadProgress = `profile_picture_progress`;
		var totalSize = `profile_picture_size`;
		let formData = new FormData();

		formData.append('action', 'uploadImage');
		formData.append('environment', process.env.NODE_ENV);
		formData.append('nodecode', true);
		formData.append('jwt', this.props.cookies.get('tk'));
		formData.append(`new_image`, this.refs.profile_picture.files[0]);

		const config = {
			onUploadProgress: (progressEvent) => {
				this.setState({
					[uploadProgress]: progressEvent.loaded,
					[totalSize]: progressEvent.total
				})
			}
		}

		axios.post(`${routes.DOMAIN}/v2/users.php`, formData, config)
		.then((result) => {
			if((result.data).startsWith("/assets/img")){
				this.setState({
					[imageName]: result.data,
					[errorName]: null
				})
			}else{
				this.setState({
					[imageName]: null,
					[errorName]: result.data
				})
				popupMessage({
					type: 'error',
					message: 'Napaka pri nalaganju slike!'
				})
			}
		})
	}

	componentWillMount(){
		this.getUserDetails();
	}

	render(){

		const user = this.state.user;

		if(this.state.user){
			return(
				<div className="animsition">
					{/* Preloader Start Here */}
					<div className="click-capture" />
					{/* Preloader End Here */}
					<div id="wrapper" className="wrapper wrp-top boxed-full">
						<Header {...this.props} active='profile'/>
						<TopHeader config={{title:'Profil'}}/>

						{/* Start Trainer details area */}
						<div className="section-space-all accent-color">
							<div className="container">
								<div className="row">
									<div className="col-lg-4 col-md-6 col-sm-6">
										<div className="trainer-detail-image">
											<div className="detail-image">
												{this.state.isEditingUser ?
													<React.Fragment>
														<input ref='profile_picture' type="file" id='profile_picture_upload' style={{display: 'none'}} onInput={() => this.uploadImage()} onChange={() => isSafari ? this.uploadImage() : null}/>
														<label htmlFor='profile_picture_upload' style={{backgroundImage: `url('${this.state.profile_picture_url ? this.state.profile_picture_url : user.avatar}')`, height: 370}} className='image-upload-area'>
															<div className={"image-upload-area-inner " + (this.state.profile_picture_url ? "change_message" : null)}>
																<React.Fragment>
																	<span><svg xmlns="http://www.w3.org/2000/svg" className='image-icon' viewBox="0 0 32 32" version="1.1" width="64px" height="64px"><g id="surface1"><path d="M 2 5 L 2 27 L 30 27 L 30 5 Z M 4 7 L 28 7 L 28 20.90625 L 22.71875 15.59375 L 22 14.875 L 17.46875 19.40625 L 11.71875 13.59375 L 11 12.875 L 4 19.875 Z M 24 9 C 22.894531 9 22 9.894531 22 11 C 22 12.105469 22.894531 13 24 13 C 25.105469 13 26 12.105469 26 11 C 26 9.894531 25.105469 9 24 9 Z M 	11 15.71875 L 20.1875 25 L 4 25 L 4 22.71875 Z M 22 17.71875 L 28 23.71875 L 28 25 L 23.03125 25 L 18.875 20.8125 Z "/></g></svg></span>
																	{this.state.profile_picture_url !== "Izberite sliko" ? <span>Spremeni sliko</span> : null}
																</React.Fragment>
																{this.state.profile_picutre_error ? <span style={{color: '#e80000'}}>{this.state.profile_picutre_error}</span> : null}
															</div>
														</label>
														<ProgressBar progress={this.state.profile_picture_progress} size={this.state.profile_picture_size} error={this.state.profile_picture_error}/>
													</React.Fragment>
												: <img src={user.avatar} alt="team" className="img-responsive" />}
											</div>

										</div>
									</div>

									<div className="col-lg-8 col-md-6 col-sm-6">
										<div className="trainer-detail-content">
											<div className="trainer-info">
												{!this.state.isEditingUser ?
													this.state.isUpdatingPassword ?
														<React.Fragment>
															<h3>{user.first_name} {user.last_name}</h3>
															<h4 style={{textTransform: 'capitalize'}}>{user.access_level}</h4>
															<p>E-mail: <span>{user.email}</span></p>

															<p>
																<label>Trenutno geslo: &nbsp;</label>
																<input ref='current_password' className="custom-input" type='password'/><br/>
																<label>Novo geslo: &nbsp;</label>
																<input ref='new_password' className="custom-input" type='password' onChange={this.checkMatchingPassword}/><br/>
																<label>Potrdite novo geslo: &nbsp;</label>
																<input ref='new_password_confirm' className="custom-input" type='password' onChange={this.checkMatchingPassword}/><br/>
															</p>

															<p><button ref='password_save_button' className='password_save btn-checkout' type='text' onClick={this.updateUserPassword} disabled={!this.state.isValid}>Shrani</button></p>
															<button className='btn-accent-fill-ghost' type='text' onClick={() => this.setState({isUpdatingPassword: false})}>Prekliči</button>
														</React.Fragment>
														:
														<React.Fragment>
															<h3>{user.first_name} {user.last_name}</h3>
															<h4 style={{textTransform: 'capitalize'}}>{user.access_level}</h4>
															<p>E-mail: <span>{user.email}</span></p>
															<p><button className='btn-accent-fill-ghost' type='button' onClick={() => this.setState({isEditingUser: true})}>Uredi</button></p>
															<button className='btn-checkout' type='button' onClick={() => this.setState({isUpdatingPassword: true})}>Spremeni geslo</button>
														</React.Fragment>
														: 
														<div className='profile-wrapper'>
															
															<label>Ime: &nbsp;</label><input ref='new_first_name' className="custom-input" type='text' defaultValue={user.first_name}/>
															<label>Priimek: &nbsp;</label><input ref='new_last_name' className="custom-input" type='text' defaultValue={user.last_name}/>

															<label>Nivo dostopa: </label><h4 style={{textTransform: 'capitalize', margin: 0}}>{user.access_level}</h4>
															<label>E-mail: &nbsp;</label><input ref='new_email' className="custom-input" type='text' defaultValue={user.email}/>

															<div>
																<button className='btn-checkout' type='text' onClick={this.updateUserDetails}>Shrani</button>
																<button className='btn-accent-fill-ghost' type='text' onClick={() => this.setState({isEditingUser: false})}>Prekliči</button>
															</div>
														</div>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* End Trainer details area */}
					</div>
						<Footer/>
				</div>
			);
		}else{
			return(<LoadingPage/>);
		}
	}
}