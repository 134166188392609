import React, { Component } from 'react';
import { render, unmountComponentAtNode } from 'react-dom'

export default class StatusMessage extends Component {

	handleClickButton = button => {
		if(button.onClick){
			button.onClick();
			removeElement();
		}else{
			removeElement();
		}
	}
	// TODO: Close on click outside of main window, close on ESC key ?
	render () {
		const { title, message, message2, buttons } = this.props
	
		return (
		<div className='react-confirm-alert-overlay' ref={dom => (this.overlay = dom)} onClick={this.handleClickOverlay}>
			<div className='react-confirm-alert'>
				<div className='react-confirm-alert-body'>
					{title && <h1>{title}</h1>}
					{message}
					<br/>
					<span>{message2 ? message2 : null}</span>
					<div className='react-confirm-alert-button-group'>
						{buttons.map((button, i) => (
						<button className={button.style === 'primary' ? 'btn-accent-fill-ghost' : 'btn-checkout'} key={i} onClick={() => this.handleClickButton(button)}>
							{button.label}
						</button>
						))}
					</div>
				</div>
			</div>
		</div>
		)
	}
}

function createElement (properties) {
	let divTarget = document.getElementById('react-confirm-alert');
	
	if (divTarget) {
		// Rerender - the mounted ReactConfirmAlert
		render(<StatusMessage {...properties} />, divTarget)
	} else {
		// Mount the ReactConfirmAlert component
		divTarget = document.createElement('div')
		divTarget.id = 'react-confirm-alert'
		document.getElementById('root').appendChild(divTarget)
		render(<StatusMessage {...properties} />, divTarget)
	}
}

function removeElement () {
	const target = document.getElementById('react-confirm-alert')
	unmountComponentAtNode(target)
	target.parentNode.removeChild(target)
	}

export function statusMessage(settings){
	createElement(settings);
}
