import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { statusMessage } from '../components/StatusMessage';
import { popupMessage } from '../components/PopupMessage';
import * as routes from '../routes';

export default class InactiveTrainingEntry extends Component {

	constructor(props){
		super(props);

		this.state = {

		}
	}

	deleteTraining = (training_id) => {
		axios.post(`${routes.DOMAIN}/v2/trainings.php`, {
			action: 'deleteTraining',
			jwt: this.props.cookies.get('tk'),
			training_id: training_id
		})
			.then((result) => {
				if(result.data == true){
					popupMessage({
						type: 'success',
						message: 'Trening uspešno izbrisan.',
					});
					this.props.refreshTrainings();
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri brisanju treninga!',
					});
				}
			})
	}

	warnDelete = () => {
		statusMessage({
			title: 'Opozorilo',
			message: 'Ali ste prepričani da želite izbrisati trening?',
			buttons: [
				{
					label: 'Da',
					style: 'primary',
					onClick: () => this.deleteTraining(this.props.training.id)
				},
				{
					label: 'Ne'
				}
			]
		});
	};

	render() {

		const training = this.props.training;

		return (
			<tr>
				<td><Link to={`${routes.defaultTraining}/${training.id}`}>{training.title}</Link></td>
				<td className='admin-training-description'><Link to={`${routes.defaultTraining}/${training.id}`}>{training.description}</Link></td>
				<td><button type='button' style={{outline: 'none'}} className='btn-checkout' onClick={() => this.props.history.push(`${routes.defaultTraining}/${training.id}/edit`)}>Uredi</button></td>
				<td><button type='button' style={{outline: 'none'}} className='btn-accent-fill-ghost' onClick={() => this.warnDelete()}>Izbriši</button></td>
			</tr>
		)
	}
}
