import React, { Component } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';

import * as routes from '../routes';

import Header from "../components/Header";
import Footer from "../components/Footer";
import TrainingEntry from "../components/TrainingEntry";
import TopHeader from "../components/TopHeader";
import TrainingGhostEntry from "../components/TrainingGhostEntry";

export default class Trainings extends Component {

	constructor(props){
		super(props);

		this.state = {
			trainings: null,
			isFiltering: false,
			selectedTags: []
		}
	}

	getTrainings = () => {
		axios.post(`${routes.DOMAIN}/v2/trainings.php`, {
			action: 'getTrainings',
			jwt: this.props.cookies.get('tk')
		})
			.then((result) => {
				this.setState({
					trainings: result.data
				})
			})
	}

	searchHandler = (clear = false) => {
		if(!this.state.isFiltering || this.state.selectedTags.length === 0){
			this.searchTrainings(clear);
		}else{
			this.specificSearch(clear);
		}
	}

	searchTrainings = (clear = false) => {
		axios.post(`${routes.DOMAIN}/v2/search.php`, {
			action: 'searchTrainings',
			jwt: this.props.cookies.get('tk'),
			search_string: this.refs.training_search.value
		})
			.then((result) => {
				//console.log(result.data);
				let searchResult = result.data.map(training => { return training.title; });
				let filteredTrainings = this.state.trainings.filter(training => searchResult.includes(training.title));

				if(this.refs.training_search.value.length === 0 || clear == true){
					this.refs.training_search.value = '';
					filteredTrainings = null;
					this.setState({
						selectedTags: []
					})
				}

				this.setState({
					searchResultsTraining: filteredTrainings
				})
			})
	}

	specificSearch = (clear = false) => {
		axios.post(`${routes.DOMAIN}/v2/search.php`, {
			action: 'specificSearch',
			jwt: this.props.cookies.get('tk'),
			search_string: this.refs.training_search.value,
			search_tags: this.state.selectedTags
		})
			.then((result) => {
				//console.log(result.data);

				let searchResult = result.data.map(training => { return training.title; });
				let filteredTrainings = this.state.trainings.filter(training => searchResult.includes(training.title));

				this.setState({
					searchResultsTraining: filteredTrainings
				})
			})
	}

	getAllTags = () => {
		axios.post(`${routes.DOMAIN}/v2/tags.php`, {
			action: 'getTags',
			jwt: this.props.cookies.get('tk')
		})
			.then((result) => {
				//console.log(result.data);

				this.setState({
					tags: result.data
				})
			})
	}

	addTagToFilter = (title) => {
		var tmp = this.state.selectedTags;

		tmp.push(title);

		this.setState({
			selectedTags: tmp
		}, () => this.searchHandler())
	}

	removeTagFromFilter = (title) => {
		var tmp = this.state.selectedTags;
		var position = this.state.selectedTags.indexOf(title);

		if(position > -1){
			tmp.splice(position, 1);
		}

		this.setState({
			selectedTags: tmp
		}, () => this.searchHandler())
	}

	closeFiltersHandler = () => {
		this.setState({
			isFiltering: false, 
			selectedTags: []
		}, () => this.searchHandler())
	}

	componentWillMount(){
		this.getTrainings();
		this.getAllTags();
		window.scrollTo(0,0);
	}

	render() {
		return (
			<div id='top' className="animsition">
				{/* Preloader Start Here */}
				<div className="click-capture" />
				{/* Preloader End Here */}
				<div id="wrapper" className="wrapper wrp-top boxed-full">
					<Header {...this.props} active='home'/>
					<TopHeader config={{title:'Naši Treningi'}}/>
					{/* Popular Classes 3 Area Start Here */}
					<div className="section-space-all body-bg">
						<div className="container classes-list-wrapper" id="inner-isotope">

							<div className='row homepage-toprow'>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 search-bar-wrapper'>
									<div className="input-group stylish-input-group homepage-search">
										<input ref='training_search' type="text" style={{textTransform: 'none'}} className="form-control" placeholder="Search" onChange={this.searchHandler}/>
										<span className="input-group-addon">
											<button type="submit">
												{this.state.searchResultsTraining ? <span className="glyphicon glyphicon-remove" onClick={() => this.searchTrainings(true)}/> : <span className="glyphicon glyphicon-search" />}
											</button>
										</span>
									</div>
								</div>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-0'></div>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 filter-button-wrapper'>
									<ul className="filtar-search-btn-area filter-btn-area">
										<li className="product-filter-btn">
											<button id="filter-btn" onClick={() => this.setState({isFiltering: !this.state.isFiltering})}>Filtri<i className="fa fa-filter" aria-hidden="true"></i></button>
										</li>
									</ul>
								</div>
							</div>

							<div className={"row filter-parameter-wrapper filter-area " + (this.state.isFiltering ? "open" : "closed")}>
								<div className="close"><i className="fa fa-times" aria-hidden="true" onClick={this.closeFiltersHandler}></i></div>
								<div className="single-item-filter-parameter">
									<h4>Kategorije</h4>
									<ul className="filter-tag">
										{this.state.tags ? this.state.tags.map((tag) => {
											if(this.state.selectedTags.indexOf(tag.title) > -1){
												return(
													<li key={tag.title}><button onClick={() => this.removeTagFromFilter(tag.title)} className='selected'>{tag.title}</button></li>
												)
											}else{
												return(
													<li key={tag.title}><button onClick={() => this.addTagToFilter(tag.title)}>{tag.title}</button></li>
												)
											}
										}) : null}
									</ul>
								</div>
							</div>

							<div className="row featuredContainer classes-list">
								{this.state.searchResultsTraining ? this.state.searchResultsTraining.map((training) => {
									return(
										<TrainingEntry key={training.title} training={training}/>
									)
								}) : this.state.trainings ? this.state.trainings.map((training) => {
									return(
										<TrainingEntry key={training.id} training={training}/>
									)
								}) :
									<React.Fragment>
										<TrainingGhostEntry/>
										<TrainingGhostEntry/>
										<TrainingGhostEntry/>
										<TrainingGhostEntry/>
										<TrainingGhostEntry/>
										<TrainingGhostEntry/>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
					{/* Popular Classes 3 Area End Here */}
				</div>
					<Footer/>
			</div>
		);
	}
}
