import React, { Component } from 'react'

export default class TrainingGhostEntry extends Component {
	render() {
		return (
			<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 yoga meditation classes-item">
				
				<div className="classes-box-layout1">
					
					<div className="item-img-wrapper">
						<div style={{width: 370, height: 207}} className='ghost-background'></div>
					</div>
					
					<div className="item-content-wrapper">
						<div className="info-view">
							<div style={{width: 72, height: 72, borderRadius: 50}} className='ghost-background'></div>
						</div>
						
						<span>
							<div style={{width: 75, height: 19}} className='ghost-background'></div>
						</span>
						
						<h3>
							<div style={{width: 180, height: 30}} className='ghost-background'></div>
						</h3>
						
						<div className="more-holder">
							<div style={{width: 40, height: 30}} className='ghost-background'></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
