export const DOMAIN = process.env.NODE_ENV === 'production' ? 'https://treningi.mito.si/api/' : 'http://localhost/api/';

export const defaultTraining = '/treningi';
export const addTraining = '/treningi/novtrening';

export const profile = '/profil';

export const admin = '/admin';

export const users = '/uporabniki';

export const login = '/prijava';
