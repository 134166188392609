import React, { Component } from 'react';
import axios from 'axios';
import Textarea from 'react-textarea-autosize';
import {isSafari} from 'react-device-detect';

import * as routes from '../routes';

import { statusMessage } from '../components/StatusMessage';
import { popupMessage } from '../components/PopupMessage';
import ProgressBar from '../components/ProgressBar';

export default class NoteEntry extends Component{

	constructor(props){
		super(props);

		this.state = {
			isEditingNote: false
		}
	}

	editNote = (note_id) => {

		let formData = new FormData();

		formData.append('action', 'editNote');
		formData.append('environment', process.env.NODE_ENV)
		formData.append('nodecode', true);
		formData.append('jwt', this.props.cookies.get('tk'));
		formData.append('note_id',  note_id);
		formData.append('note_new_title',  this.refs.note_new_title.value);
		formData.append('note_new_content',  this.note_new_content.value);
		formData.append('note_new_picture_url',  this.state.note_new_picture_url ? this.state.note_new_picture_url : undefined);

		axios.post(`${routes.DOMAIN}/v2/notes.php`, formData)
			.then((result) => {
				console.log(result.data);
				if(result.data){
					this.setState({
						isEditingNote: false,
					});
					
					popupMessage({
						type: 'success',
						message: 'Opomba shranjena'
					})
					this.props.refreshNotes();
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri shranjevanju opombe!'
					})
				}
			})
	}

	deleteNote = (note_id) => {
		axios.post(`${routes.DOMAIN}/v2/notes.php`, {
			action: 'deleteNote',
			environment: process.env.NODE_ENV,
			jwt: this.props.cookies.get('tk'),
			note_id: note_id
		})
			.then((result) => {
				if(result.data == true){
					this.props.refreshNotes();
					popupMessage({
						type: 'success',
						message: 'Opomba uspešno izbrisana.'
					})
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri brisanju opombe!'
					})
				}

			})
	}

	promptDelete = () => {
		statusMessage({
			title: 'Opozorilo',
			message: 'Ali ste prepričani da želite izbrisati opombo?',
			buttons: [
				{
					label: 'Da',
					style: 'primary',
					onClick: () => this.deleteNote(this.props.note.id)
				},
				{
					label: 'Ne'
				}
			]
		});
	}

	uploadImage = () => {
		var imageName = `note_new_picture_url`;
		var errorName = `note_new_picture_error`;
		var uploadProgress = `note_new_picture_progress`;
		var totalSize = `note_new_picture_size`;
		let formData = new FormData();

		formData.append('action', 'uploadImage');
		formData.append('environment', process.env.NODE_ENV);
		formData.append('nodecode', true);
		formData.append('jwt', this.props.cookies.get('tk'));
		formData.append(`new_image`, this.refs.note_new_picture.files[0]);

		const config = {
			onUploadProgress: (progressEvent) => {
				this.setState({
					[uploadProgress]: progressEvent.loaded,
					[totalSize]: progressEvent.total
				})
			}
		}

		axios.post(`${routes.DOMAIN}/v2/notes.php`, formData, config)
		.then((result) => {
			if((result.data).startsWith("/assets/img")){
				this.setState({
					[imageName]: result.data,
					[errorName]: null
				})
			}else{
				this.setState({
					[imageName]: null,
					[errorName]: result.data
				})
				popupMessage({
					type: 'error',
					message: 'Napaka pri nalaganju slike!'
				})
			}
		})
	}

	render(){

		const note = this.props.note;

		return(
			<div className='noteWrapper'>
				{!this.state.isEditingNote ? (
					<div>
						<h3>{note.title}</h3>
						{note.picture !== '' ? <img src={note.picture} height={300} alt={note.title}/> : null}
						<span>{note.content}</span>
						<div className='noteButtonsWrapper'>
							<button type='button' className='btn-checkout' onClick={() => this.setState({isEditingNote: true})}>Uredi</button>
							<button type='button' className='btn-accent-fill-ghost' onClick={() => this.promptDelete()}>Izbriši</button>
						</div>
					</div>
				) :
				(
					<form key={note.id}>
						<p className='login-input-title'>Naslov</p>
						<input ref='note_new_title' type='text' className='custom-input' defaultValue={note.title}/><br/>

						<p className='login-input-title'>Slika</p>
						<input ref='note_new_picture' id='note_new_picture' type="file" style={{display: 'none'}} onInput={() => this.uploadImage()} onChange={() => isSafari ? this.uploadImage() : null}/>
						<label htmlFor='note_new_picture' style={{height: 255, backgroundImage: `url('${this.state.note_new_picture_url ? this.state.note_new_picture_url : this.state.note_new_picture_error ? null : note.picture}')`}} className='image-upload-area old'>
							<div className={"image-upload-area-inner " + (this.state.note_new_picture_url ? "change_message" : null)}>
								<React.Fragment>
									<span><svg xmlns="http://www.w3.org/2000/svg" className='image-icon' viewBox="0 0 32 32" version="1.1" width="64px" height="64px"><g id="surface1"><path d="M 2 5 L 2 27 L 30 27 L 30 5 Z M 4 7 L 28 7 L 28 20.90625 L 22.71875 15.59375 L 22 14.875 L 17.46875 19.40625 L 11.71875 13.59375 L 11 12.875 L 4 19.875 Z M 24 9 C 22.894531 9 22 9.894531 22 11 C 22 12.105469 22.894531 13 24 13 C 25.105469 13 26 12.105469 26 11 C 26 9.894531 25.105469 9 24 9 Z M 	11 15.71875 L 20.1875 25 L 4 25 L 4 22.71875 Z M 22 17.71875 L 28 23.71875 L 28 25 L 23.03125 25 L 18.875 20.8125 Z "/></g></svg></span>
									<span>Zamenjaj sliko</span>
								</React.Fragment>
							</div>
						</label>
						<ProgressBar progress={this.state.note_new_picture_progress} size={this.state.note_new_picture_size} error={this.state.note_new_picture_error}/>

						
						<p className='login-input-title'>Opis</p>
						<Textarea inputRef={textarea => (this.note_new_content = textarea)} type='text' className='custom-input' defaultValue={note.content}></Textarea><br/>
						<div className='noteButtonsWrapper'>
							<button type='button' className='btn-checkout' onClick={() => this.editNote(note.id)}>Shrani</button>
							<button type='button' className='btn-accent-fill-ghost' onClick={() => this.setState({isEditingNote: false})}>Prekliči</button>
						</div>
					</form>
				)}
			</div>
		);
	}
}