import React from 'react';

const AdminIcon = () => (
	<div className='admin-icon-wrapper' title='Administrator'>
		{/*<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='rgb(234, 0, 39)' viewBox='0 0 24 24'>
			<path d='M12 0c-3.436-.012-6.928 1.225-9 3v11.536c0 4.602 3.204 5.803 9 9.464 5.796-3.661 9-4.863 9-9.464v-11.536c-2.072-1.775-5.564-3.012-9-3z'/>
		</svg>*/}
		<svg fill='rgb(234, 0, 39)' xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 737.408 737.407'>
			<path d='M674.417,123.507c-0.224-9.759-7.216-18.177-16.982-19.511c-45.521-6.252-90.416-17.129-134.426-33.248 c-43.802-16.042-92.267-38.436-144.54-67.93c-6.637-3.747-12.88-3.77-19.51,0c-51.911,29.479-100.029,51.826-144.177,67.93 c-44.024,16.058-88.897,27.004-134.056,33.248c-9.767,1.349-16.62,9.752-17.707,19.511 c-4.332,185.366,36.27,337.036,121.414,454.934c46.384,64.222,104.431,116.349,174.526,156.096c5.419,3.978,13.915,3.677,20.235,0 c69.564-40.41,127.534-92.159,173.811-156.096C638.262,460.62,678.533,308.88,674.417,123.507z M520.48,555.677 c-40.225,55.564-90.694,101.895-151.762,139.112c-61.069-37.218-111.777-83.378-151.763-139.112 c-77.458-107.962-115.269-246.08-113.828-414.463c42.637-6.868,84.92-18.432,127.195-33.972 c42.275-15.533,88.528-36.856,138.396-63.952c49.868,27.096,95.757,48.419,138.033,63.952 c42.275,15.541,84.912,27.104,127.557,33.972C635.75,310.684,597.699,448.995,520.48,555.677z'
			/>
		</svg>
	</div>
);

export default AdminIcon;