import React, { Component } from 'react';
import { Progress } from 'react-sweet-progress';

export default class ProgressBar extends Component {
	render() {

		const {progress, size, error} = this.props;

		if(progress){
			return(
				<Progress percent={Math.floor(progress / size * 100)} status={error ? "error" : size == progress ? "success" : "active"} />
			)
		}else{
			return(
				null
			)
		}
		
	}
}
