import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import * as routes from '../routes';

import Header from '../components/Header';

import { popupMessage } from '../components/PopupMessage';

import Footer from '../components/Footer';
import TopHeader from '../components/TopHeader';

export default class Login extends Component {

	handleLogin = (e) => {
		e.preventDefault();
		axios.post(`${routes.DOMAIN}/login.php`, {
			email: this.refs.emailInput.value,
			password: this.refs.passwordInput.value
		})
			.then((result) => {
				if(result.data.length > 20){
					this.props.cookies.set('tk', result.data, { path: '/', expires: new Date(moment().add(2, 'd').format()) });
					this.props.checkJWT(this.props.cookies.get('tk'));
					popupMessage({
						type: 'success',
						message: 'Uspešno prijavljeni'
					})
				}else{
					if(result.data == 'noactiv'){
						popupMessage({
							type: 'error',
							message: 'Račun ni aktiviran!'
						})
					}else{
						popupMessage({
							type: 'error',
							message: 'Prijava neuspešna'
						})
					}
				}
			})
	}

	render() {
		return (
			<div className="animsition">
				{/* Preloader Start Here */}
				<div className="click-capture" />
				{/* Preloader End Here */}
				<div id="wrapper" className="wrapper login-page-wrapper">
					<div className='login-container'>
						<div className='login'>
							<div className='login-form-wrapper'>
								<h2>Pozdravljeni!</h2>
								<p className='subtext'>Prosimo prijavite se v vaš račun</p>
								<form>
									<ul>
										<li>
											<div className="form-group has-error has-danger">
												<p className='login-input-title'>Email</p>
												<input ref='emailInput' type="text" name="email" id="form-name" data-error="Name field is required" spellCheck={false} required />
											</div>
										</li>
										<li>
											<div className="form-group has-error has-danger">
												<p className='login-input-title'>Password</p>
												<input ref='passwordInput' type="password" name="password" id="form-phone" data-error="Phone field is required" required />
											</div>
										</li>
									</ul>

									<button type='submit' style={{outline: 'none', borderRadius: 4}} className="btn-checkout" onClick={this.handleLogin}>Prijava</button>
								</form>
							</div>

							<div className='login-contact-bar'>
								<div><i className="icon fa fa-phone" />&nbsp;<span className="txt-dark">(+386) 041 764 321</span></div>
								<div><i className="icon fa fa-envelope" />&nbsp;<span className="txt-dark">mito@sinkovec.si</span></div>
							</div>

							<div className='login-image-wrapper'>
								<div className='login-image-overlay'></div>
								<img src='/assets/img/side-pic.jpg' alt='Sidebar'/>
							</div>
						</div>
					</div>

				</div>
			</div>
		)
	}
}
