import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/sl';
import StarRatings from 'react-star-ratings';
import axios from 'axios';
import Textarea from 'react-textarea-autosize';
import { isSafari } from 'react-device-detect';

import * as routes from '../routes';

import { popupMessage } from './PopupMessage';
import ProgressBar from './ProgressBar';

moment.locale('sl');

export default class EditTrainingContent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			new_img: 0,
			checked: !!this.props.training.activated,
			header_picture_url: this.props.training.picture
		}
	}

	changeRating = (rating, name) => {
		console.log(name);
		this.setState({
			[name]: rating
		})
	}

	changeNewRating = (rating, name) => {
		console.log(name);
		this.setState({
			[name]: rating
		})
	}

	newImage = () => {
		this.setState({
			new_img: this.state.new_img + 1
		})
	}

	updateTraining = (event) => {

		event.preventDefault();

		let formData = new FormData();
		let image_ids = [];
		let modified_images = [];

		formData.append('action', 'editTraining');
		formData.append('environment', process.env.NODE_ENV);
		formData.append('nodecode', true);
		formData.append('jwt', this.props.cookies.get('tk'));
		formData.append('training_id', this.props.match.params.id);
		formData.append('new_title', this.refs.new_title.value);
		formData.append('new_description', this.new_description.value);
		formData.append('new_images_number', this.state.new_img);
		formData.append('new_picture', this.state.header_picture_url);
		formData.append('new_activated', this.state.checked);

		for (let i = 0; i < this.props.images.length; i++) {
			//formData.append(`image_${i}`, this.refs[`image_${i}`].files[0]);
			formData.append(`image_url_${i}`, this.state[`image_url_${i}`] ? this.state[`image_url_${i}`] : this.props.images[i].url);
			formData.append(`image_description_${i}`, this[`image_description_${i}`].value);
			formData.append(`image_rate_${i}`, this.state[`rating_${i}`] ? this.state[`rating_${i}`] : this.props.images[i].rate);
			if (this.refs[`image_${i}`].files[0] !== undefined) {
				modified_images.push((this.refs[`image_${i}`].id).replace('img_upload_', ''));
			}

			image_ids.push((this.refs[`image_${i}`].id).replace('img_upload_', ''));
		}

		for (let a = 0; a < this.state.new_img; a++) {
			//formData.append(`new_image_${a}`, this.refs[`new_image_${a}`].files[0]);
			formData.append(`new_image_url_${a}`, this.state[`new_image_url_${a}`]);
			formData.append(`new_image_description_${a}`, this[`new_image_description_${a}`].value);
			formData.append(`new_image_rate_${a}`, this.state[`new_rating_${a}`] || 0);
		}

		if (image_ids == '') {
			image_ids = -1
		}
		formData.append('image_ids', image_ids);
		//console.log(image_ids);
		formData.append('modified_images', modified_images);

		console.log(formData, "formakshdjkashdjaksdhijashd");

		axios.post(`${routes.DOMAIN}/v2/trainings.php`, formData)
			.then((result) => {
				if (result.data == true) {
					this.props.switchEdit(false, true);
					popupMessage({
						type: 'success',
						message: 'Trening uspešno shranjen!'
					})
					window.scrollTo(0, 0);
				} else {
					popupMessage({
						type: 'error',
						message: 'Napaka pri shranjevanju treninga!'
					})
				}
			})

	}

	uploadImage = (type, index) => {
		var imageName, errorName, uploadProgress, totalSize;
		let formData = new FormData();

		formData.append('action', 'uploadImage');
		formData.append('environment', process.env.NODE_ENV);
		formData.append('nodecode', true);
		formData.append('jwt', this.props.cookies.get('tk'));
		switch (type) {
			case "new":
				imageName = `new_image_url_${index}`;
				uploadProgress = `new_image_${index}_progress`;
				totalSize = `new_image_${index}_size`;
				errorName = `new_image_error_${index}`;
				formData.append(`new_image`, this.refs[`new_image_${index}`].files[0]);
				//var rotate = this.state[`new_img_${index}_rotation`] || 0;
				var rotate = this.state[`new_img_${index}_rotation`] != null ? this.state[`new_img_${index}_rotation`] + 90 : 0;
				formData.append('rotate_deg', rotate);
				this.setState({
					[`new_img_${index}_rotation`]: rotate
				})
				break;
			case "old":
				imageName = `image_url_${index}`;
				uploadProgress = `image_${index}_progress`;
				totalSize = `image_${index}_size`;
				errorName = `image_error_${index}`;
				formData.append(`new_image`, this.refs[`image_${index}`].files[0]);
				var rotate = this.state[`old_img_${index}_rotation`] != null ? this.state[`old_img_${index}_rotation`] + 90 : 0;
				formData.append('rotate_deg', rotate);
				this.setState({
					[`old_img_${index}_rotation`]: rotate
				})
				break;
			case "header":
				imageName = `header_picture_url`;
				uploadProgress = `header_picture_progress`;
				totalSize = `header_picture_size`;
				errorName = `header_picture_error`;
				formData.append(`new_image`, this.refs[`header_picture`].files[0]);
				var rotate = this.state[`header_img_rotation`] != null ? this.state[`header_img_rotation`] + 90 : 0;
				formData.append('rotate_deg', rotate);
				this.setState({
					[`header_img_rotation`]: rotate
				})
				break;
			default:
				break;
		}

		const config = {
			onUploadProgress: (progressEvent) => {
				this.setState({
					[uploadProgress]: progressEvent.loaded,
					[totalSize]: progressEvent.total
				})
			}
		}

		axios.post(`${routes.DOMAIN}/v2/trainings.php`, formData, config)
			.then((result) => {
				//console.log(result.data);
				if ((result.data).startsWith("/assets/img")) {
					this.setState({
						[imageName]: result.data,
						[errorName]: null
					})
				} else {
					this.setState({
						[imageName]: null,
						[errorName]: result.data
					})
					popupMessage({
						type: 'error',
						message: 'Napaka pri nalaganju slike!'
					})
				}
			})
	}

	deleteImage = (index, id) => {
		var images = this.props.images
		images.splice(index, 1);
		this.setState({
			images: images
		})

		axios.post(`${routes.DOMAIN}/v2/trainings.php`, {
			action: 'deleteTrainingImage',
			jwt: this.props.cookies.get('tk'),
			environment: process.env.NODE_ENV,
			picture_id: id
		})
			.then((result) => {
				//console.log(result);
			})
	}

	handleCheck = () => {
		this.setState({
			checked: !this.state.checked
		})
	}

	componentDidMount() {
		for (var i = 0; i < this.props.images.length; i++) {
			var varName = `rating_${i}`;

			this.setState({
				[varName]: this.props.images[i].rate
			})
		}
	}

	render() {

		const training = this.props.training;
		const images = this.props.images;

		const image_dom = [];

		for (var i = 0; i < this.state.new_img; i++) {
			var varNewName = `new_rating_${i}`;
			var descName = `new_image_description_${i}`;
			var index = i;

			image_dom.push(
				<div key={i} style={{ position: 'relative', marginBottom: 20 }}>
					<input ref={`new_image_${index}`} type="file" id={`new_img_upload_${index}`} style={{ display: 'none' }} onInput={() => this.uploadImage("new", index)} onChange={() => isSafari ? this.uploadImage("new", index) : null} />
					<div style={{ overflow: 'hidden' }}>
						<label htmlFor={`new_img_upload_${i}`} style={{ backgroundImage: `url('${this.state[`new_image_url_${index}`] ? this.state[`new_image_url_${index}`] : "none"}')` }} className='image-upload-area'>
							<div className={"image-upload-area-inner " + (this.state[`new_image_url_${index}`] ? "change_message" : null)}>
								<React.Fragment>
									<span><svg xmlns="http://www.w3.org/2000/svg" className='image-icon' viewBox="0 0 32 32" version="1.1" width="64px" height="64px"><g id="surface1"><path d="M 2 5 L 2 27 L 30 27 L 30 5 Z M 4 7 L 28 7 L 28 20.90625 L 22.71875 15.59375 L 22 14.875 L 17.46875 19.40625 L 11.71875 13.59375 L 11 12.875 L 4 19.875 Z M 24 9 C 22.894531 9 22 9.894531 22 11 C 22 12.105469 22.894531 13 24 13 C 25.105469 13 26 12.105469 26 11 C 26 9.894531 25.105469 9 24 9 Z M 	11 15.71875 L 20.1875 25 L 4 25 L 4 22.71875 Z M 22 17.71875 L 28 23.71875 L 28 25 L 23.03125 25 L 18.875 20.8125 Z " /></g></svg></span>
									{this.state[`new_image_error_${index}`] !== "Izberite sliko" ? <span>{this.state[`new_image_url_${index}`] ? "Zamenjaj sliko" : "Naloži sliko"}</span> : null}
								</React.Fragment>
								{this.state[`new_image_error_${index}`] ? <span style={{ color: '#e80000' }}>{this.state[`new_image_error_${index}`]}</span> : null}
							</div>
						</label>
					</div>
					{this.state[`new_image_url_${index}`] ?
						<div className='rotateIcon-wrapper' onClick={() => this.uploadImage("new", index)}>
							<img className='rotateIcon' src='/assets/img/rotate.svg' height={32} alt='Rotate' />
						</div>
						: null
					}

					<ProgressBar progress={this.state[`new_image_${index}_progress`]} size={this.state[`new_image_${index}_size`]} error={this.state[`new_image_error_${index}`]} />

					<div className='image-info'>
						<h4 style={{ textTransform: 'capitalize' }}>{moment().format('LLLL')}</h4>
						<StarRatings rating={this.state[varNewName] ? this.state[varNewName] : 0} changeRating={this.changeNewRating} starDimension="24px" starRatedColor="#ffdf88" numberOfStars={5} name={`new_rating_${i}`} />
					</div>

					<Textarea inputRef={textarea => (this[descName] = textarea)} className='custom-input new-image'></Textarea>
					{this.state.new_img - 1 === i ? <button type='button' style={{ padding: "6px 10px" }} className='btn-accent-fill-ghost' onClick={() => this.setState({ new_img: this.state.new_img - 1, [`new_image_url_${index}`]: null, [`new_image_${index}_progress`]: null, [`new_rating_${index}`]: null })}>Izbriši</button> : null}
				</div>)
		}

		return (
			<div className="class-details-inner-top">
				<div className="class-img-holder">
					<div>
						<input ref='header_picture' type="file" id='header_picture_upload' style={{ display: 'none' }} onInput={() => this.uploadImage("header")} onChange={() => isSafari ? this.uploadImage("header") : null} />
						<label htmlFor='header_picture_upload' style={{ backgroundImage: `url('${this.state.header_picture_url ? this.state.header_picture_url : "none"}')` }} className='image-upload-area'>
							<div className={"image-upload-area-inner " + (this.state.header_picture_url ? "change_message" : null)}>
								<React.Fragment>
									<span><svg xmlns="http://www.w3.org/2000/svg" className='image-icon' viewBox="0 0 32 32" version="1.1" width="64px" height="64px"><g id="surface1"><path d="M 2 5 L 2 27 L 30 27 L 30 5 Z M 4 7 L 28 7 L 28 20.90625 L 22.71875 15.59375 L 22 14.875 L 17.46875 19.40625 L 11.71875 13.59375 L 11 12.875 L 4 19.875 Z M 24 9 C 22.894531 9 22 9.894531 22 11 C 22 12.105469 22.894531 13 24 13 C 25.105469 13 26 12.105469 26 11 C 26 9.894531 25.105469 9 24 9 Z M 	11 15.71875 L 20.1875 25 L 4 25 L 4 22.71875 Z M 22 17.71875 L 28 23.71875 L 28 25 L 23.03125 25 L 18.875 20.8125 Z " /></g></svg></span>
									{this.state.header_picture_url !== "Izberite sliko" ? <span>{this.state.header_picture_url ? "Zamenjaj naslovno sliko" : "Naloži naslovno sliko"}</span> : null}
								</React.Fragment>
								{this.state.header_picutre_error ? <span style={{ color: '#e80000' }}>{this.state.header_picutre_error}</span> : null}
							</div>
						</label>
					</div>

					{this.state.header_picture_size != null ?
						<div className='rotateIcon-wrapper' onClick={() => this.uploadImage("header")}>
							<img className='rotateIcon' src='/assets/img/rotate.svg' height={32} alt='Rotate' />
						</div>
						: null
					}

					<ProgressBar progress={this.state.header_picture_progress} size={this.state.header_picture_size} error={this.state.header_picture_error} />

				</div>

				<div className="class-details-top-content">
					<form>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
							<h2 style={{ marginBottom: 0 }}><input ref='new_title' style={{ marginTop: 0 }} className='custom-input' type='text' defaultValue={training.title} placeholder='Naslov' /></h2>
						</div>

						<ul className="class-comments">
							<li><i className="fa fa-calendar-minus-o" aria-hidden="true" />Zadnja posodobitev : <span>{moment(training.date_modify).format('ll')}</span></li>
							<li><i className="fa fa-user" aria-hidden="true" />Trener :<span> {training.first_name + " " + training.last_name}</span></li>
							<li>
								<div className='checkbox'>
									<input ref='new_activated' className='checkbox__input' id="checkbox_training" type='checkbox' defaultChecked={this.state.checked} onChange={this.handleCheck} />
									<label htmlFor="checkbox_training" className="checkbox__label">Aktiviran</label>
								</div>
							</li>
						</ul>

						<p className="mb-40">
							<Textarea inputRef={textarea => (this.new_description = textarea)} className='custom-input image-description' defaultValue={training.description}></Textarea>
						</p>

						{images !== undefined ? images.map((image, index) => {
							var varName = `rating_${index}`;
							var imgDescName = `image_description_${index}`;

							return (
								<div key={image.id} className='mb-40'>

									<div>
										<input ref={`image_${index}`} id={`img_upload_${image.id}`} type="file" style={{ display: 'none' }} onInput={() => this.uploadImage("old", index)} onChange={() => isSafari ? this.uploadImage("old", index) : null} />
										<label htmlFor={`img_upload_${image.id}`} style={{ backgroundImage: `url('${this.state[`image_url_${index}`] ? this.state[`image_url_${index}`] : image.url}')` }} className='image-upload-area old'>
											<div className={"image-upload-area-inner " + (this.state[`image_url_${index}`] ? "change_message" : null)}>
												<React.Fragment>
													<span><svg xmlns="http://www.w3.org/2000/svg" className='image-icon' viewBox="0 0 32 32" version="1.1" width="64px" height="64px"><g id="surface1"><path d="M 2 5 L 2 27 L 30 27 L 30 5 Z M 4 7 L 28 7 L 28 20.90625 L 22.71875 15.59375 L 22 14.875 L 17.46875 19.40625 L 11.71875 13.59375 L 11 12.875 L 4 19.875 Z M 24 9 C 22.894531 9 22 9.894531 22 11 C 22 12.105469 22.894531 13 24 13 C 25.105469 13 26 12.105469 26 11 C 26 9.894531 25.105469 9 24 9 Z M 	11 15.71875 L 20.1875 25 L 4 25 L 4 22.71875 Z M 22 17.71875 L 28 23.71875 L 28 25 L 23.03125 25 L 18.875 20.8125 Z " /></g></svg></span>
													<span>Zamenjaj sliko</span>
												</React.Fragment>
												{this.state[`image_error_${index}`] ? <span style={{ color: '#e80000' }}>{this.state[`image_error_${index}`]}</span> : null}
											</div>
										</label>
									</div>

									{this.state[`image_${index}_size`] != null ?
										<div className='rotateIcon-wrapper' onClick={() => this.uploadImage("old", index)}>
											<img className='rotateIcon' src='/assets/img/rotate.svg' height={32} alt='Rotate' />
										</div>
										: null
									}

									<ProgressBar progress={this.state[`image_${index}_progress`]} size={this.state[`image_${index}_size`]} error={this.state[`image_error_${index}`]} />
									{/*<img src={image.url} alt={image.description} height={300}/>
									<input ref={`image_${index}`} id={image.id} className='custom-input' type='file'/><br/>*/}

									<div className='image-info'>
										<h4 style={{ textTransform: 'capitalize' }}>{moment(image.date_modify).format('LLLL')}</h4>
										<StarRatings ref={`rating_${index}`} rating={this.state[varName] ? this.state[varName] : image.rate} changeRating={this.changeRating} starDimension="24px" starRatedColor="#ffdf88" numberOfStars={5} name={`rating_${index}`} />
									</div>

									<p>
										<Textarea inputRef={textarea => (this[imgDescName] = textarea)} ref={`image_description_${index}`} className='custom-input' defaultValue={image.description}></Textarea>
									</p>

									<button type='button' className='btn-accent-fill-ghost' onClick={() => this.deleteImage(index, image.id)}>Izbriši</button>
								</div>
							);
						}) : null}

						{image_dom}

						<button type='button' className='btn-checkout' onClick={this.newImage}>Dodaj sliko</button><br /><br />

						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<button type='submit' style={{ marginRight: 10 }} className='btn-checkout' onClick={this.updateTraining}>Shrani</button>
							<button type='button' className='btn-accent-fill-ghost' onClick={() => this.props.switchEdit(false)}>Prekliči</button>
						</div>

					</form>
				</div>
			</div>
		)
	}
}