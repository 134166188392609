import React, { Component } from "react";
import { Link } from 'react-router-dom';

import Footer from "../components/Footer";
import Header from "../components/Header";

export default class Error404 extends Component {
	render() {
		return (
			<div className="animsition">
				{/* Preloader Start Here */}
				<div className="click-capture" />
				{/* Preloader End Here */}
				<div id="wrapper" className="wrapper wrp-top boxed-full">
					{/* Header Area Start Here */}
					<Header />
					{/* Our Store Area Start Here */}
					{/* Inner Page Banner Area Start Here */}
					<div
						className="inner-page-banner-area"
						style={{ backgroundImage: 'url("/assets/img/banner/404.jpg")' }}
					>
						<div className="container">
							<div className="pagination-area">
								<h2 className="inner-section-title-textprimary">
									404<span> Error</span>
								</h2>
								<ul>
									<li>
										<Link to='/'>Home</Link> -
									</li>
									<li>Error 404</li>
								</ul>
							</div>
						</div>
					</div>
					{/* Inner Page Banner Area End Here */}
					{/* 404 Page Area Start Here */}
					<div className="error-page body-bg section-space-bottom">
						<div className="container">
							<h2>404</h2>
							<h3>Stran ne obstaja!</h3>
							<p>
								Zgleda, da ne najdemo strani, ki jo iščete. Vrnite se na &nbsp;
								<Link to='/'>vstopno stran</Link>
							</p>
						</div>
					</div>
					{/* 404 Page Area End Here */}
					{/* Footer Area Start Here */}
					{/* Footer Area End Here */}
				</div>
					<Footer />
			</div>
		);
	}
}
