import React, { Component } from 'react';
import axios from 'axios';

import * as routes from '../routes';
import { popupMessage } from './PopupMessage';

export default class AddUser extends Component {

	handleRegister = (event) => {
		event.preventDefault();

		if(this.refs.new_email.value !== '' || this.refs.new_password.value !== ''){
			
			axios.post(`${routes.DOMAIN}/v2/users.php`, {
				action: 'addUser',
				jwt: this.props.cookies.get('tk'),
				firstName: this.refs.new_first_name.value,
				lastName: this.refs.new_last_name.value,
				email: this.refs.new_email.value,
				password: this.refs.new_password.value,
				accessLevel: this.refs.new_access_level.value
			})
			.then((result) => {
				//console.log(result);
				if(result.data == true){
					popupMessage({
						type: 'success',
						message: 'Uporabnik uspešno dodan.'
					})
					this.props.switchAddUser(false, true);
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri dodajanju uporabnika!'
					})
				}
			})

		}
	}

	render() {
		return (
			<div className='addUser-wrapper'>
				<div className='addUser-form-container'>
					<h3>Dodaj uporabnika</h3>
					<form className='addUser' onSubmit={this.handleRegister}>
						<label>Ime: </label>
						<input ref='new_first_name' type='text' spellCheck={false} className='custom-input' required/>
						<label>Priimek: </label>
						<input ref='new_last_name' type='text' spellCheck={false} className='custom-input' required/>
						<label>Email: </label>
						<input ref='new_email' type='text' spellCheck={false} className='custom-input' required/>
						<label>Nivo dostopa: </label>
						<select ref='new_access_level' className='custom-input'>
							<option value='user' className='custom-input'>Uporabnik</option>
							<option value='administrator' className='custom-input'>Administrator</option>
						</select>

						<label>Geslo:</label>
						<input ref='new_password' type='password' placeholder='Geslo' className='custom-input' required/>
					
						<div className='addUser-buttons'>
							<button type='submit' className='btn-checkout'>Dodaj</button>
							<button type='button' className='btn-checkout white' onClick={() => this.props.switchAddUser(false)}>Prekliči</button>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
