import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import * as routes from '../routes';

export default class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpened: false
		};
	}

	handleLogOut = () => {
		this.props.cookies.remove('tk', {path: '/'});
		window.location.reload();
	}

	render() {

		const active = this.props.active;

		return (
			<div className={this.state.isOpened ? "menu-is-opened" : "menu-is-closed"}>
				{this.state.isLoggedIn !== false ?
					<div className="menu remove_side_photo">
						<span className="close-menu ion-close-round right-boxed" onClick={() => this.setState({isOpened: false})} />

						{this.props.session ?
							<div className="menu-lang right-boxed">
								<span>Prijavljeni kot: </span>
								<Link to={routes.profile} className="active">{this.props.session.firstName + ' ' + this.props.session.lastName}</Link>
							</div>
						: null}
						
						<div className="menu-lang right-boxed"></div>
						
						<ul className="menu-list right-boxed">
							<li className={active === "home" ? "active" : null}>
								<Link to='/'>Domov</Link>
							</li>
							<li className={active === "trainings" ? "active" : null}>
								<Link to={routes.defaultTraining}>Treningi</Link>
							</li>
							<li className={active === "contact" ? "active" : null}>
								<HashLink to='/#end'>Kontakt</HashLink>
							</li>

							<li className={active === "profile" ? "active" : null}>
								<Link to={routes.profile}>Profil</Link>
							</li>

							{this.props.session ?
								this.props.session.accessLevel === "administrator" ? 
								(
									<li className={active === "admin" ? "active" : null}>
										<Link to={routes.admin}>Admin</Link>
									</li>
								) : null
							: null}

							{this.props.session ?
								<li>
									<button style={{outline: 'none'}} type='button' className='btn-checkout' onClick={this.handleLogOut}>Odjava</button>
								</li>
							: null}
						</ul>

						<div className="menu-footer right-boxed">
							<div className="social-list">
								<a href="https://www.facebook.com/Telodrom/" target='_blank' rel="noopener noreferrer" className="icon ion-social-facebook"> </a>
								<a href="https://www.instagram.com/telodrom/" target='_blank' rel="noopener noreferrer" className="icon ion-social-instagram"> </a>

							</div>

							<div className="copy">© Copyright!</div>
						</div>
					</div>
				: null}

				<header className="navbar boxed-full nav-pills nav-stacked">
					<div className="navbar-bg" />
					
					{this.props.isLoggedIn !== false ? 
						<button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false" onClick={() => this.setState({isOpened: true})}>
							<span className="icon-bar" />
							<span className="icon-bar" />
							<span className="icon-bar" />
						</button>
					: null}
					
					<Link to='/' className="brand">
						<img className="img-responsive" src="/assets/img/logo.jpg" alt="logo" />
					</Link>

					<address className="navbar-address visible-lg">
						<i className="icon fa fa-phone" />
						&nbsp;
						&nbsp;
						<span className="txt-dark">(+386) 041 764 321</span>
					</address>

					{this.props.session ?
						<div className="header-box-top txt-light visible-lg">
							<div style={{marginRight: 10, marginTop: 2}} className="menu-lang">
								<Link to={routes.profile} className="active">{this.props.session.firstName + ' ' + this.props.session.lastName}</Link>
							</div>
						</div>
					: null}

				</header>
			</div>
		);
	}
}
