import React, { Component } from 'react';
import axios from 'axios';

import * as routes from '../routes.js';

import Header from '../components/Header';
import Footer from '../components/Footer';
import TagEntry from '../components/TagEntry.js';
import NoteEntry from '../components/NoteEntry.js';
import TrainingContent from '../components/TrainingContent.js';
import EditTrainingContent from '../components/EditTrainingContent.js';
import Comment from '../components/Comment.js';
import TopHeader from '../components/TopHeader.js';
import SidebarTrainingEntry from '../components/SidebarTrainingEntry.js';
import AddNote from '../components/AddNote.js';
import DetailsLoading from '../components/DetailsLoading.js';
import SidebarDetailsLoading from '../components/SidebarDetailsLoading';
import CommentLoading from '../components/CommentLoading.js';

export default class TrainingDetails extends Component{

	constructor(props){
		super(props);

		this.state = {
			training: null,
			tags: null,
			filteredTags: null,
			owner: false,
			isAddingTag: false,
			isEditingTraining: false,
			isAddingNote: false,
			width: 0
		}
	}

	getTrainings = () => {
		axios.post(`${routes.DOMAIN}/v2/trainings.php`, {
			action: 'getTrainings',
			jwt: this.props.cookies.get('tk')
		})
			.then((result) => {
				//console.log(result);
				this.setState({
					trainings: result.data
				})
			})
	}

	getTraining = (training_id) => {
		axios.post(`${routes.DOMAIN}/v2/trainings.php`, {
			action: 'getTrainings',
			jwt: this.props.cookies.get('tk'),
			training_id: training_id
		})
			.then((result) => {
				console.log(result.data, "training data");
				var [training] = result.data;
				var tags = result.data[1];
				var images = result.data[2];

				this.setState({
					training: training,
					tags: tags,
					images: images,
				}, () => this.removeSelectedTags(), this.checkOwnership(training.user_id), this.getTrainings())
			})
	}

	getAllTags = () => {
		axios.post(`${routes.DOMAIN}/v2/tags.php`, {
			action: 'getTags',
			jwt: this.props.cookies.get('tk')
		})
			.then((result) => {
				//console.log(result.data);

				this.setState({
					allTags: result.data
				}, () => this.removeSelectedTags())
			})
	}

	addTag = (tag_name) => {
		// TODO: Add ui for description and icon
		axios.post(`${routes.DOMAIN}/v2/tags.php`, {
			action: 'addTag',
			jwt: this.props.cookies.get('tk'),
			training_id: this.props.match.params.id,
			input_tag: tag_name
		})
			.then((result) => {
				//var [r] = result.data;
				//console.log(result.data);
				//window.location.reload();
				this.getTraining(this.props.match.params.id);
				this.setState({
					isAddingTag: false,
					tagSearchResults: null
				})
			})
	}

	removeSelectedTags = (search = false) => {
		try{

			let selectedTags = this.state.tags.map(selectedTag => { return selectedTag.title; });
			let filteredTags = this.state.allTags.filter(tag => !selectedTags.includes(tag.title));

			if(search){
				filteredTags = this.state.tagSearchResults.filter(tag => !selectedTags.includes(tag.title));
			}

			this.setState({
				filteredTags: filteredTags
			})
		}catch{}
	}

	searchTags = () => {
		axios.post(`${routes.DOMAIN}/v2/search.php`, {
			action: 'searchTags',
			jwt: this.props.cookies.get('tk'),
			search_string: this.refs.input_tag.value
		})
			.then((result) => {
				//console.log(result);
				this.setState({
					tagSearchResults: result.data
				}, () => this.removeSelectedTags(true))
			})
	}

	checkOwnership = (user_id) => {
		if(this.props.session.user_id === user_id || this.props.session.accessLevel === 'administrator'){
			this.setState({
				owner: true
			})
		}else{
			this.setState({
				owner: false
			})
		}
	}

	getNotes = (training_id) => {
		axios.post(`${routes.DOMAIN}/v2/notes.php`, {
			action: 'getNotes',
			jwt: this.props.cookies.get('tk'),
			training_id: training_id
		})
			.then((result) => {
				//console.log(result.data);
				this.setState({
					notes: result.data
				})
			})
	}

	switchEdit = (state, refresh) => {
		this.setState({
			isEditingTraining: state
		})

		if(refresh){
			this.getTraining(this.props.match.params.id)
		}
	}

	switchAddNote = (state, refresh) => {
		this.setState({
			isAddingNote: state
		})

		if(refresh){
			this.getNotes(this.props.match.params.id)
		}
	}

	getComments = (training_id) => {
		axios.post(`${routes.DOMAIN}/v2/comments.php`, {
			action: 'getComments',
			jwt: this.props.cookies.get('tk'),
			training_id: training_id
		})
			.then((result) => {
				//console.log(result.data);
				this.setState({
					comments: result.data
				})
			})
	}

	addComment = (event) => {
		event.preventDefault();

		axios.post(`${routes.DOMAIN}/v2/comments.php`, {
			action: 'addComment',
			jwt: this.props.cookies.get('tk'),
			training_id: this.props.match.params.id,
			comment_content: this.refs.comment_content.value
		})
			.then((result) => {
				//console.log(result.data);
				if(result.data != false){
					this.refs.comment_content.value = '';
					this.getComments(this.props.match.params.id);
				}
			})
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.location.pathname !== this.props.location.pathname) {
			this.runOnLoad(nextProps.match.params.id);
			window.scrollTo(0,0);
		}
	}

	runOnLoad = (training_id) => {
		this.getTraining(training_id);
		this.getAllTags();
		this.getNotes(training_id);
		this.getComments(training_id);

		if(this.props.match.params.edit && (this.props.session.accessLevel === 'administrator' || this.state.owner === true)){
			this.setState({
				isEditingTraining: true
			})
		}
	}

	componentWillMount(){
		this.runOnLoad(this.props.match.params.id);
		window.scrollTo(0,0);

		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}
	
	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth});
	}

	render(){
		return(
			<div className="animsition">
				{/* Preloader Start Here */}
				<div className="click-capture" />
				{/* Preloader End Here */}
				<div id="wrapper" className="wrapper wrp-top boxed-full light-dark">
					<Header {...this.props}/>
					<TopHeader config={{title: this.state.training ? this.state.training.title : '12loading12', pages: [{name: 'treningi', link: 'defaultTraining'}]}}/>
					{/* Class Details Page Area Start Here */}
					<div className="class-details-area body-bg section-space-all">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-7 col-xs-12">
									<div style={{borderBottom: '1px solid #e4e4e4', marginBottom: 26, paddingBottom: 26}} className="class-details-inner">
										{this.state.training ?
											!this.state.isEditingTraining ? 
											<TrainingContent training={this.state.training} images={this.state.images} switchEdit={this.switchEdit} owner={this.state.owner}/>
											:
											<EditTrainingContent training={this.state.training} images={this.state.images} cookies={this.props.cookies} {...this.props} switchEdit={this.switchEdit}/>
											:
											<DetailsLoading/>
										}
									</div>
								</div>

								<div className="col-lg-4 col-md-4 col-sm-5 col-xs-12">
									<div className="thegym-sidebar">
										<div className="sidebar-box">
											<div className="categories">
												<h3 className="title-sidebar">Opombe</h3>
												{this.state.notes ? this.state.notes.map((note, index) => {
													return(
														<NoteEntry key={index} note={note} {...this.props} refreshNotes={() => this.getNotes(this.props.match.params.id)}/>
													)
												}) : (
													<React.Fragment>
														<div style={{width: 370, height: 380}} className='ghost-background'></div>
														<br/>
														<div style={{width: 370, height: 380}} className='ghost-background'></div>
														<br/>
													</React.Fragment>
												)}
											</div>
											{this.state.isAddingNote ? <AddNote {...this.props} {...this.state} refreshNotes={this.switchAddNote}/> : <button type='button' className='btn-checkout addNoteButton' onClick={() => this.switchAddNote(true, false)}>Nova opomba</button>}
										</div>

										<div className="sidebar-box">
											<div className={this.state.tags ? "product-tags" : 'ghost-list'}>
												<h3 className="title-sidebar">Kategorije</h3>
												<ul className='tag-list'>
													{this.state.tags ? this.state.tags.map((tag, index) => {
														return(
															<TagEntry key={index} tag={tag} owner={this.state.owner} {...this.props} refreshTags={() => this.getTraining(this.props.match.params.id)}/>
														)
													}) : (
														<React.Fragment>
															<li style={{width: 70, height: 36}} className='ghost-background'></li>
															<li style={{width: 70, height: 36}} className='ghost-background'></li>
															<li style={{width: 70, height: 36}} className='ghost-background'></li>
															<li style={{width: 70, height: 36}} className='ghost-background'></li>
															<li style={{width: 70, height: 36}} className='ghost-background'></li>
															<li style={{width: 70, height: 36}} className='ghost-background'></li>
															<li style={{width: 70, height: 36}} className='ghost-background'></li>
															<li style={{width: 70, height: 36}} className='ghost-background'></li>
															<li style={{width: 70, height: 36}} className='ghost-background'></li>
														</React.Fragment>
													)}
												</ul>

												{this.state.owner ?
													!this.state.isAddingTag ?
														<li className='noPlus'><button type='button' onClick={() => this.setState({isAddingTag: true})}>+</button></li>
													: (
														<div className='sidebar-search-area'>
															<div style={{width: 259}} className="input-group stylish-input-group">
																
																<input ref='input_tag' type="text" className="tagSearchBar" placeholder="Ime oznake" onChange={this.searchTags} onBlur={(event) => setTimeout(() => {this.setState({tagSearchResults: null})}, 200)}/>
																{this.state.tagSearchResults ? 
																<ul style={{position: 'absolute'}}>
																	{this.state.filteredTags.map((tag, index) => {
																		return( <li key={index} className='searchResultTags' onClick={() => {this.refs.input_tag.value = tag.title; this.setState({tagSearchResults: null})}}>{tag.title}</li>)
																	})}	
																</ul> : null}
																
																<div style={{marginTop: 12}}>
																	<span className="input-group-addon tagSearchButton">
																		<button type="submit" onClick={() => this.addTag(this.refs.input_tag.value)}>Dodaj</button>
																	</span>

																	<span className="input-group-addon tagSearchButton tag-cancel-button">
																		<button type='button' onClick={() => this.setState({isAddingTag: false})}>Prekliči</button>
																	</span>
																</div>
															</div>
														</div>
													)
													: null
												}
										</div>

										<div className="sidebar-box">
											<div className="popular-post">
												<h3 className="title-sidebar">Drugi Treningi</h3>
												
												<div className="tab-content">
													<div className="popular-post tab-pane fade active in" id="Popular">
														{this.state.trainings ? this.state.trainings.slice(0, 5).map((training) => {
															if(training.id !== this.state.training.id){
																return(
																	<SidebarTrainingEntry key={training.id} training={training}/>
																);
															}else{
																return null;
															}
														}) : (
															<React.Fragment>
																<SidebarDetailsLoading/>
																<SidebarDetailsLoading/>
																<SidebarDetailsLoading/>
																<SidebarDetailsLoading/>
															</React.Fragment>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								
							</div>

						</div>

						<div className="blog-leave-comments">
							<h3>Napišite komentar</h3>
							<div className="row">
								<form>
									<fieldset>
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<div className="form-group">
												<textarea ref='comment_content' placeholder="Vaš komentar..." className="textarea form-control" id="form-message" rows="8" cols="20"></textarea>
												<div className="help-block with-errors"></div>
											</div>
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<div className="form-group mb-none">
												<button type="submit" className="btn-checkout" onClick={this.addComment}>Oddaj komentar</button>
											</div>
										</div>
									</fieldset>
								</form>
							</div>
						</div>
									
						{this.state.comments ?
							<div className="blog-comments-list">
								<h3>{this.state.comments.length} Komentarjev</h3>

								{this.state.comments.map((comment, index) => {
									return(
										<Comment key={index} comment={comment} {...this.props} getComments={this.getComments}/>
									)
								})}
								<div id='blog-leave-comments'></div>
							</div>
						: (
							<div className="blog-comments-list">
								<h3>Komentarji</h3>

								<CommentLoading/>
								<CommentLoading/>
								<CommentLoading/>
							</div>
						)}
					</div>
				</div>
			</div>
				<Footer/>
			</div>
		);
	}
}