import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as routes from '../routes';

export default class SidebarTrainingEntry extends Component {
	render() {

		const training = this.props.training;

		return (
			<div className="media sidebar-training-entry">
				<Link to={`${routes.defaultTraining}/${training.id}`} className="pull-left">
					<img alt="Training Img" src={training.picture} className="img-responsive" />
				</Link>
				<div className="media-body">
					<Link to={`${routes.defaultTraining}/${training.id}`}>{training.title}</Link>
					<ul className="post-info">
						<li>{training.first_name + " " + training.last_name}</li>
					</ul>
				</div>
			</div>
		)
	}
}
