import React, { Component } from 'react'
import axios from 'axios';

import * as routes from '../routes';
import { popupMessage } from './PopupMessage.js';

export default class EditUser extends Component {

	constructor(props){
		super(props);

		this.state = {
			isActivated: this.props.user.activated,
			checked: this.props.user.activated,
			isButtonDisabled: true,
			isChangeButtonDisabled: true
		}
	}

	updateUser = () => {
		axios.post(`${routes.DOMAIN}/v2/users.php`, {
			action: 'editUserAdmin',
			jwt: this.props.cookies.get('tk'),
			id: this.props.user.id,
			new_first_name: this.refs.new_first_name.value,
			new_last_name: this.refs.new_last_name.value,
			new_email: this.refs.new_email.value,
			new_activated: this.state.checked,
			new_access_level: this.refs.new_access_level.value,
		})
			.then((result) => {
				if(result.data == true){
					popupMessage({
						type: 'success',
						message: 'Shranjevanje uspešno'
					})
					this.props.updateEditUserState(false);
					this.props.refreshUsers();
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri shranjevanju!'
					})
				}
			})
	}

	handlePasswordChange = () => {
		axios.post(`${routes.DOMAIN}/v2/users.php`, {
			action: 'changePassword',
			jwt: this.props.cookies.get('tk'),
			id: this.props.user.id,
			current_password: '',
			new_password: this.refs.new_password.value
		})
			.then((result) => {
				//console.log(result.data)
				if(result.data == true){
					popupMessage({
						type: 'success',
						message: 'Geslo uspešno spremenjeno'
					})
					this.props.updateEditUserState(false);
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri spreminjanju gesla!'
					})
				}
			})
	}

	handleCheck = () => {
		this.setState({
			checked: !this.state.checked
		})
	}

	checkPassword = () => {
		if(this.refs.new_password.value === this.refs.confirm_new_password.value){
			this.setState({
				isChangeButtonDisabled: false
			})
		}else{
			this.setState({
				isChangeButtonDisabled: true
			})
		}
	}

	render() {

		const user = this.props.user;

		return (
			<div className='editUser-wrapper'>
				<div className='editUser'>
					<h3>Urejanje Uporabnika</h3>
					<form className='editUser-data' onChange={() => this.setState({isButtonDisabled: false})}>
						<label>Ime: </label>
						<input ref='new_first_name' type='text' className='custom-input' defaultValue={user.first_name}/>

						<label>Priimek: </label>
						<input ref='new_last_name' type='text' className='custom-input' defaultValue={user.last_name}/>

						<label>Email: </label>
						<input ref='new_email' type='text' className='custom-input' defaultValue={user.email}/>

						{user.id !== 1 ? 
						<React.Fragment>
							<label>Aktiviran: </label>
							<input ref='new_activated' type='checkbox' defaultChecked={user.activated} onChange={this.handleCheck} />
						</React.Fragment>
						: null }

						{user.id !== 1 ?
							<React.Fragment>
								<label>Nivo dostopa: </label>
								<select ref='new_access_level' className='custom-input' defaultValue={user.access_level}>
									<option value='user'>Uporabnik</option>
									<option value='administrator'>Administrator</option>
								</select>
							</React.Fragment>
						: null }
						
						<div className='editUser-button-container'>
							<button type='button' className='btn-checkout' onClick={this.updateUser} disabled={this.state.isButtonDisabled}>Shrani spremembe</button>
						</div>
					</form>

					<form className='editUser-password' onChange={this.checkPassword}>
						{this.props.session.accessLevel === 'administrator' ? 
							<React.Fragment>
								<label>Novo geslo: </label>
								<input ref='new_password' className='custom-input' type='password'/>
								<label>Potrdi novo geslo: </label>
								<input ref='confirm_new_password' className='custom-input' type='password'/>
								
								<div className='editUser-button-container'>
									<button type='button' className='btn-checkout' onClick={this.handlePasswordChange} disabled={this.state.isChangeButtonDisabled}>Spremeni geslo</button>
								</div>
							</React.Fragment>
						: null}
					</form>

					<button type='button' className='btn-checkout white' onClick={() => this.props.updateEditUserState(false)}>Prekliči</button>
				</div>
			</div>
		)
	}
}
