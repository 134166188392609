import React, { Component } from 'react';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';

import * as routes from '../routes';

import Header from '../components/Header';
import Footer from '../components/Footer';
import UserEntry from '../components/UserEntry';
import InactiveTrainingEntry from '../components/InactiveTrainingEntry';
import TopHeader from '../components/TopHeader';
import AddUser from '../components/AddUser';
import { statusMessage } from '../components/StatusMessage';
import { popupMessage } from '../components/PopupMessage';
import AdminTagEntry from '../components/AdminTagEntry';


export default class AdminPanel extends Component {

	constructor(props){
		super(props);

		this.state = {
			users: null,
			selectedUserPage: 1,
			filteredUsers: null,
			selectedTrainingPage: 1,
			filteredTrainings: null,
			isEditingUser: false,
			isAddingUser: false,
			selectedTagPage: 1,
			filteredTags: null
		}
	}

	getUsers = () => {
		axios.post(`${routes.DOMAIN}/v2/users.php`, {
			action: 'getUsers',
			jwt: this.props.cookies.get('tk')
		})
			.then((result) => {
				//console.log(result);
				this.setState({
					users: result.data
				}, () => this.filterUsers())
			})
	}

	getInactiveTrainings = () => {
		axios.post(`${routes.DOMAIN}/v2/trainings.php`, {
			action: 'getInactiveTrainings',
			jwt: this.props.cookies.get('tk')
		})
			.then((result) => {
				//console.log(result);
				this.setState({
					inactiveTrainings: result.data
				})
			})
	}

	filterUsers = () => {
		var count = this.state.users.length;
		var pages = Math.ceil(count/10);

		var lowerLimit = this.state.selectedUserPage * 10 - 10;
		var upperLimit = this.state.selectedUserPage * 10;


		var filteredUsers = this.state.users.slice(lowerLimit, upperLimit);

		this.setState({
			filteredUsers: filteredUsers,
			pages: pages
		})
		//console.log(filteredUsers);
	}

	changeUserPage = (action) => {
		var page;

		if(action === "back"){
			page = this.state.selectedUserPage - 1 < 1 ? 1 : this.state.selectedUserPage - 1;
			this.setState({
				selectedUserPage: page
			}, () => this.filterUsers())
		}else if(action === "next"){
			page = this.state.selectedUserPage + 1 > this.state.pages ? this.state.selectedUserPage : this.state.selectedUserPage + 1;
			this.setState({
				selectedUserPage: page
			}, () => this.filterUsers())
		}else{
			this.setState({
				selectedUserPage: action
			}, () => this.filterUsers())
		}
		//console.log(action);
	}

	filterTrainings = () => {
		var count = this.state.trainings.length;
		var pages = Math.ceil(count/10);

		var lowerLimit = this.state.selectedTrainingPage * 10 - 10;
		var upperLimit = this.state.selectedTrainingPage * 10;


		var filteredTrainings = this.state.trainings.slice(lowerLimit, upperLimit);

		this.setState({
			filteredTrainings: filteredTrainings,
			trainingPages: pages
		})
		//console.log(filteredUsers);
	}

	changeTrainingPage = (action) => {
		var page;

		if(action === "back"){
			page = this.state.selectedTrainingPage - 1 < 1 ? 1 : this.state.selectedTrainingPage - 1;
			this.setState({
				selectedTrainingPage: page
			}, () => this.filterTrainings())
		}else if(action === "next"){
			page = this.state.selectedTrainingPage + 1 > this.state.trainingPages ? this.state.selectedTrainingPage : this.state.selectedTrainingPage + 1;
			this.setState({
				selectedTrainingPage: page
			}, () => this.filterTrainings())
		}else{
			this.setState({
				selectedTrainingPage: action
			}, () => this.filterTrainings())
		}
		//console.log(action);
	}


	deleteUser = (id) => {
		axios.post(`${routes.DOMAIN}/v2/users.php`, {
			action: 'deleteUser',
			jwt: this.props.cookies.get('tk'),
			user_id: id
		})
			.then((result) => {
				//console.log(result.data);
				if(result.data == true){
					popupMessage({
						type: 'success',
						message: 'Uporabnik uspešno izbrisan'
					})
					this.getUsers();
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri brisanju uporabnika'
					})
				}
			})
	}

	getTrainings = () => {
		axios.post(`${routes.DOMAIN}/v2/trainings.php`, {
			action: 'getTrainings',
			jwt: this.props.cookies.get('tk')
		})
			.then((result) => {
				this.setState({
					trainings: result.data
				}, () => this.filterTrainings())
			})
	}

	switchAddUser = (state, refresh) => {
		this.setState({
			isAddingUser: state
		})

		if(refresh){
			this.getUsers();
		}
	}

	getTags = () => {
		axios.post(`${routes.DOMAIN}/v2/tags.php`, {
			action: 'getTags',
			jwt: this.props.cookies.get('tk')
		})
			.then((result) => {
				//console.log(result.data);

				this.setState({
					tags: result.data
				}, () => this.filterTags())
			})
	}

	filterTags = () => {
		var count = this.state.tags.length;
		var pages = Math.ceil(count/10);

		var lowerLimit = this.state.selectedTagPage * 10 - 10;
		var upperLimit = this.state.selectedTagPage * 10;


		var filteredTags = this.state.tags.slice(lowerLimit, upperLimit);

		this.setState({
			filteredTags: filteredTags,
			tagPages: pages
		})
		//console.log(filteredUsers);
	}

	changeTagPage = (action) => {
		var page;

		if(action === "back"){
			page = this.state.selectedTagPage - 1 < 1 ? 1 : this.state.selectedTagPage - 1;
			this.setState({
				selectedTagPage: page
			}, () => this.filterTags())
		}else if(action === "next"){
			page = this.state.selectedTagPage + 1 > this.state.tagPages ? this.state.selectedTagPage : this.state.selectedTagPage + 1;
			this.setState({
				selectedTagPage: page
			}, () => this.filterTags())
		}else{
			this.setState({
				selectedTagPage: action
			}, () => this.filterTags())
		}
		//console.log(action);
	}

	promptDelete = (id) => {
		statusMessage({
			title: 'Opozorilo',
			message: `Ali ste prepričani da želite izbrisati uporabnika?`,
			message2: `Njegovi treningi bodo prestavljeni na ${this.props.session.email}, komentarji in opombe pa izbrisani!`,
			buttons: [
				{
					label: 'Da',
					style: 'primary',
					onClick: () => this.deleteUser(id)
				},
				{
					label: 'Ne'
				}
			]
		});
	}

	componentWillMount(){
		this.getTrainings();
		this.getUsers();
		this.getInactiveTrainings();
		this.getTags();

		window.scrollTo(0,0);
	}


	render() {

		var indicators = [];
		var trainingIndicators = [];
		var tagIndicators = [];

		// users
		for(var i = 1; i<=this.state.pages;i++){
			indicators.push(
				<li key={i} className={this.state.selectedUserPage === i ? "active" : null}><button type='button' className='nav-buttons' onClick={this.changeUserPage.bind(null, i)}>{i}</button></li>
			)
		}

		// Trainings
		for(var j = 1; j<=this.state.trainingPages;j++){
			trainingIndicators.push(
				<li key={j} className={this.state.selectedTrainingPage === j ? "active" : null}><button type='button' className='nav-buttons' onClick={this.changeTrainingPage.bind(null, j)}>{j}</button></li>
			)
		}

		// Tags
		for(var j = 1; j<=this.state.tagPages;j++){
			tagIndicators.push(
				<li key={j} className={this.state.selectedTagPage === j ? "active" : null}><button type='button' className='nav-buttons' onClick={this.changeTagPage.bind(null, j)}>{j}</button></li>
			)
		}

		return (
			<div className="animsition">
				{/* Preloader Start Here */}
				{this.props.session.accessLevel !== 'administrator' ? <Redirect to='/'/> : null}
				<div className="click-capture" />
				{/* Preloader End Here */}
				<div id="wrapper" className="wrapper wrp-top boxed-full">

					<Header {...this.props} active='admin'/>
					<div>
						<TopHeader config={{title: 'Nadzorna plošča'}}/>
						{/* Banner Area End Here */}
						<div data-stellar-background-ratio="0.5" className="timetable-wrapper section-space-all  bg-stellar-inline-style">
							<div style={{marginBottom: 12}} className='container'>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'></div>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
									<h2 style={{marginBottom: 0}} className="section-title">Uporabniki</h2>
								</div>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 btn-admin-big'>
									<button style={{paddingTop: 11, paddingBottom: 11}} className='btn-checkout' onClick={() => this.switchAddUser(true)}>Nov uporabnik</button>
									{this.state.isAddingUser ? <AddUser {...this.props} switchAddUser={this.switchAddUser}/> : null}
								</div>
							</div>

							<div className="container">
								<div className="intro">
									<div style={{boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'}} className="row classes-timetable2-wrapper">
										<div style={{position: 'unset'}} className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<div style={{height: 732}} id="myTabContent" className="tab-content class-schedule-tab classes-tab-wrapper">
												<div className="tab-pane fade in active" id="monday">
													<div className="table-responsive">
														<table className="table">
															<thead style={{position: 'unset'}}>
																<tr>
																	<th>Ime</th>
																	<th>Priimek</th>
																	<th>Email</th>
																	<th>Aktiviran</th>
																	<th>Rang</th>
																	<th>Uredi</th>
																	<th>Izbriši</th>
																</tr>
															</thead>
															<tbody>
																{this.state.filteredUsers ? 
																this.state.filteredUsers.map((user, index) => {
																	return (<UserEntry key={index} user={user} {...this.props} deleteUser={this.promptDelete} refreshUsers={this.getUsers} />)
																}) : null}
															</tbody>
														</table>
													</div>
												</div>
											</div>
											<ul className="pagination-center">
												<li><button className='nav-buttons' onClick={() => this.changeUserPage("back")}><i className="fa fa-arrow-left" aria-hidden="true"></i></button></li>
												{indicators}
												<li><button className='nav-buttons' onClick={() => this.changeUserPage("next")}><i className="fa fa-arrow-right" aria-hidden="true"></i></button></li>
											</ul>										
										</div>
									</div>
								</div>
							</div>
						</div>

						<div data-stellar-background-ratio="0.5" className="timetable-wrapper section-space-all">
							<div style={{marginBottom: 12}} className='container'>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'></div>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
									<h2 style={{marginBottom: 0}} className="section-title">Vsi treningi</h2>
								</div>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 btn-admin-big'>
									<Link to={routes.addTraining} style={{paddingTop: 11, paddingBottom: 11}} className='btn-checkout'>Nov trening</Link>
								</div>
							</div>

							<div className="container">
								<div className="intro">
									<div style={{boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'}} className="row classes-timetable2-wrapper">
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<div style={{height: 732}} id="myTabContent" className="tab-content class-schedule-tab classes-tab-wrapper">
												<div className="tab-pane fade in active" id="monday">
													<div className="table-responsive">
														<table className="table">
															<thead style={{position: 'unset'}}>
																<tr>
																	<th>Naslov</th>
																	<th>Opis</th>
																	<th>Uredi</th>
																	<th>Izbriši</th>
																</tr>
															</thead>
															<tbody>
																{this.state.filteredTrainings ? 
																this.state.filteredTrainings.map((training, index) => {
																	return (<InactiveTrainingEntry key={index} training={training} refreshTrainings={this.getTrainings} cookies={this.props.cookies} {...this.props}/>)
																}) : null}
															</tbody>
														</table>
													</div>
												</div>
											</div>
											<ul className="pagination-center">
												<li><button className='nav-buttons' onClick={() => this.changeTrainingPage("back")}><i className="fa fa-arrow-left" aria-hidden="true"></i></button></li>
												{trainingIndicators}
												<li><button className='nav-buttons' onClick={() => this.changeTrainingPage("next")}><i className="fa fa-arrow-right" aria-hidden="true"></i></button></li>
											</ul>										
										</div>
									</div>
								</div>
							</div>
						</div>

						<div data-stellar-background-ratio="0.5" className="timetable-wrapper section-space-all">
							<div style={{marginBottom: 12}} className='container'>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'></div>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
									<h2 style={{marginBottom: 0}} className="section-title">Oznake</h2>
								</div>
								<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'></div>
							</div>

							<div className="container">
								<div className="intro">
									<div style={{boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'}} className="row classes-timetable2-wrapper">
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<div style={{height: 732}} id="myTabContent" className="tab-content class-schedule-tab classes-tab-wrapper">
												<div className="tab-pane fade in active" id="monday">
													<div className="table-responsive">
														<table className="table">
															<thead style={{position: 'unset'}}>
																<tr>
																	<th>Naslov</th>
																	<th>Uredi</th>
																	<th>Izbriši</th>
																</tr>
															</thead>
															<tbody>
																{this.state.filteredTags ? 
																this.state.filteredTags.map((tag, index) => {
																	return (<AdminTagEntry key={index} tag={tag} refreshTags={this.getTags} cookies={this.props.cookies} {...this.props}/>)
																}) : null}
															</tbody>
														</table>
													</div>
												</div>
											</div>
											<ul className="pagination-center">
												<li><button className='nav-buttons' onClick={() => this.changeTagPage("back")}><i className="fa fa-arrow-left" aria-hidden="true"></i></button></li>
												{tagIndicators}
												<li><button className='nav-buttons' onClick={() => this.changeTagPage("next")}><i className="fa fa-arrow-right" aria-hidden="true"></i></button></li>
											</ul>										
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
					<Footer/>
			</div>
		)
	}
}
