import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';

import "react-sweet-progress/lib/style.css";
import './App.css';
import './custom.scss';
import * as routes from './routes';

import Trainings from './pages/Trainings';
import UserProfile from './pages/UserProfile';
import Search from './pages/Search';
import Error404 from './pages/Error404';
import TrainingDetails from './pages/TrainingDetails';
import LoadingPage from './pages/LoadingPage';
import Login from './pages/Login';
import AdminPanel from './pages/AdminPanel';
import AddTraining from './pages/AddTraining';

const cookies = new Cookies();

class App extends Component {

	constructor(props){
		super(props);

		this.state = {
			session: null
		}
	}

	checkJWT = () => {
		const token = cookies.get('tk');

		axios.post(`${routes.DOMAIN}/v2/jwt.php`, {
			verify: true,
			jwt: token,
		})
			.then((result) => {
				if(result.data === true){
					//console.log(result);
					//console.log("y");
					this.setState({
						session: JSON.parse(atob(token.split('.')[1]))
					})
				}else{
					//console.log(result);
					//console.log('false');
					this.setState({
						session: false
					})
				}
			})
	}

	decodeToken = (token) => {
		if(token){
			this.setState({
				session: JSON.parse(atob(token.split('.')[1]))
			})
		}else{
			this.setState({
				session: null
			})
		}
	};

	componentDidMount(){
		//this.decodeToken(cookies.get('tk'))
		this.checkJWT();
	}

	render() {
		if(this.state.session === null){
			return(
				<LoadingPage/>
			);
		}else if(this.state.session !== false){
			return(
				<Switch>
					{/*<Route exact path='/old/:id' render={(props) => <DetailsTraining_old {...props} {...this.state} cookies={cookies}/>}/>*/}
					{/*<Route exact path='/inactivetrainings' render={(props) => <InactiveTrainings {...props} {...this.state} cookies={cookies}/>}/>*/}
					{/*<Route exact path='/trainings' render={(props) => <Trainings {...props} {...this.state} cookies={cookies}/>}/>*/}
					{/*<Route exact path='/users' component={Users}/>*/}
					{/*<Route exact path='/trainings/:id/edit' render={(props) => <EditTraining {...props} {...this.state} cookies={cookies}/>}/>*/}
					<Route exact path='/search' render={(props) => <Search {...props} {...this.state} cookies={cookies}/>}/>
					<Route exact path={routes.admin} render={(props) => <AdminPanel {...props} {...this.state} cookies={cookies}/>}/>
					<Route exact path={routes.profile} render={(props) => <UserProfile {...props} {...this.state} cookies={cookies}/>}/>
					<Route exact path={routes.addTraining} render={(props) => <AddTraining {...props} {...this.state} cookies={cookies}/>}/>
					<Route exact path={`${routes.defaultTraining}/:id/:edit?`} render={(props) => <TrainingDetails {...props} {...this.state} cookies={cookies}/>}/>
					<Redirect from={routes.defaultTraining} to='/' />
					{/*<Route exact path={`${routes.users}/:id`} render={(props) => <EditUser {...props} {...this.state} cookies={cookies} />}/>*/}
					<Route exact path={routes.login} render={(props) => <Login {...props} {...this.state} cookies={cookies} />}/>
					<Route exact path='/' render={(props) => <Trainings {...props} {...this.state} cookies={cookies}/>}/>
					<Route component={Error404}/>
				</Switch>
			);
		}else{
			return(
				<Login cookies={cookies} checkJWT={this.checkJWT}/>
			)
		}
	}
}

export default App;
