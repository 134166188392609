import React, { Component } from 'react';
import { render, unmountComponentAtNode } from 'react-dom'

export default class PopupMessage extends Component {

	handleClickButton = button => {
		if(button.onClick){
			button.onClick();
			removeElement();
		}else{
			removeElement();
		}
	}
	// TODO: Close on click outside of main window, close on ESC key ?
	render () {
		const { type, message } = this.props
	
		return (
		<div className='react-message-overlay' ref={dom => (this.overlay = dom)} onClick={this.handleClickOverlay}>
			<div style={type == "error" ? {background: 'rgba(238, 39, 67,.9)'} : {background: 'rgba(41, 162, 41,.9)'}} className='react-message'>
				<div className='react-message-body'>
					{message}
				</div>
			</div>
		</div>
		)
	}
}

function createElement (properties) {
	let divTarget = document.getElementById('react-message-outer');
	
	if (divTarget) {
		// Rerender - the mounted ReactConfirmAlert
		render(<PopupMessage {...properties} />, divTarget)
	} else {
		// Mount the ReactConfirmAlert component
		divTarget = document.createElement('div')
		divTarget.id = 'react-message-outer'
		document.getElementById('root').appendChild(divTarget)
		render(<PopupMessage {...properties} />, divTarget)
	}
}

function removeElement () {
	const target = document.getElementById('react-message-outer')
	unmountComponentAtNode(target)
	target.parentNode.removeChild(target)
	}

export function popupMessage(settings){
	createElement(settings);

	setTimeout(() => {
		try{
			removeElement();
		}catch{}
	}, 5000);
}
