import React, { Component } from 'react'

export default class SidebarDetailsLoading extends Component {
	render() {
		return (
			<div style={{display: 'inline-flex'}} className="media sidebar-training-entry">
				<div style={{width: 70, height: 70, marginRight: 15}} className='ghost-background'></div>

				<div style={{width: 'inherit'}} className="media-body">
					<div style={{width: 120, height: 24}} className='ghost-background'></div>

					<ul style={{marginTop: 10}} className="post-info">
						<li style={{width: 150, height: 19, display: 'block'}} className='ghost-background'></li>
					</ul>
				</div>
			</div>
		)
	}
}
