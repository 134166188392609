import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/sl';
import StarRatings from 'react-star-ratings';

moment.locale('sl');

export default class TrainingContent extends Component{
	render(){

		const training = this.props.training;
		const images = this.props.images;

		return(
			<div className="class-details-inner-top">
				<div className="class-img-holder">
					<img src={training ? training.picture : "/assets/img/default.jpg"} className="img-responsive" alt="class" />
				</div>
				
				<div className="class-details-top-content">
					<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20}}>
						<h2 style={{marginBottom: 0}}>{training.title}</h2>
						{this.props.owner ? <button type='button' style={{height: 'max-content'}} className='btn-checkout' onClick={() => this.props.switchEdit(true)}>Uredi trening</button> : null}
					</div>

					<ul className="class-comments">
						<li><i className="fa fa-calendar-minus-o" aria-hidden="true" />Zadnja posodobitev : <span>{moment(training.date_modify).format('ll')}</span></li>
						<li><i className="fa fa-user" aria-hidden="true" />Trener :<span> {training.first_name + " " + training.last_name}</span></li>
					</ul>

					<p className="mb-40">
						{training.description}
					</p>

					{images ? images.map((image) => {
						return(
							<div key={image.id} className='mb-40'>
								<img src={image.url} alt={image.description} height={400}/>

								<div className='image-info'>
									<h4 style={{textTransform: 'capitalize'}}>{moment(image.date_modify).format('LLLL')}</h4>
									<StarRatings rating={image.rate} starDimension="24px" starRatedColor="#ffdf88" numberOfStars={5} name='rating'/>
								</div>

								<p>{image.description}</p>
							</div>
						);
					}) : null}

				</div>
			</div>
		)
	}
}