import React, { Component } from 'react';
import axios from 'axios';

import * as routes from '../routes';
import { popupMessage } from './PopupMessage.js';
import { statusMessage } from './StatusMessage.js';

export default class TagEntry extends Component{

	constructor(props){
		super(props);

		this.state = {
			result: false
		}
	}

	buttonHandler = (tag_id) => {
		statusMessage({
			title: 'Opozorilo',
			message: 'Ali ste prepričani da želite izbrisati kategorijo?',
			buttons: [
				{
					label: 'Da',
					style: 'primary',
					onClick: () => this.removeTag(tag_id)
				},
				{
					label: 'Ne'
				}
			]
		});
	}

	removeTag = (tag_id) => {
		axios.post(`${routes.DOMAIN}/v2/tags.php`, {
			action: 'deleteTag',
			jwt: this.props.cookies.get('tk'),
			tag_id: tag_id,
			training_id: this.props.match.params.id
		})
			.then((result) => {
				if(result.data == true){
					this.updateTags();
					popupMessage({
						type: 'success',
						message: 'Kategorija izbrisana.'
					})
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri brisanju kategorije!'
					})
				}
			})
	}

	updateTags = () => {
		if(this.state.result){
			this.setState({
				result: false
			})
			this.props.refreshTags();
		}
	}

	render(){

		const tag = this.props.tag;

		return(
			<li className={'training-tag ' + (this.props.owner ? 'xButton' : '')} onClick={() => this.props.new ? this.props.removeGhostTag(tag.title) : null}>
				<button>{tag.title}</button>
				{this.props.owner ? <span onClick={() => this.buttonHandler(tag.id)}>X</span> : null}
			</li>
		);
	}
}