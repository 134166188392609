import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as routes from '../routes';

export default class TrainingEntry extends Component{

	render(){

		const training = this.props.training;

		return(
			<div key={training.id} className="col-lg-4 col-md-4 col-sm-6 col-xs-12 yoga meditation classes-item">
				<div className="classes-box-layout1">
					<div className="item-img-wrapper">
						<Link to={`${routes.defaultTraining}/${training.id}`}>
							<img
								src={training.picture}
								alt={training.title}
								className="img-responsive masked"
							/>
						</Link>
					</div>
					<div ref={(el) => {if (el) {el.style.setProperty('background-color', 'white', 'important');}}} className="item-content-wrapper">
						<div className="info-view">
							<Link to={`${routes.defaultTraining}/${training.id}`}>
								<img
									src={training.avatar}
									alt={training.first_name}
									className="img-responsive img-circle"
								/>
							</Link>
						</div>
						<span>
							<Link to={`${routes.defaultTraining}/${training.id}`}>
								<i className="fa fa-user" aria-hidden="true" />
								{training.first_name}
							</Link>
						</span>
						<h3>
							<Link to={`${routes.defaultTraining}/${training.id}`}>{training.title}</Link>
						</h3>
						<div className="more-holder">
							<Link to={`${routes.defaultTraining}/${training.id}`} className="icon ion-ios-arrow-thin-right" href="#" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}