import React, { Component } from 'react'

export default class CommentLoading extends Component {

	render() {
		return (
			<div style={{display: 'inline-flex'}} className="media">
				<div style={{width: 100, height: 100, marginRight: 25}} className='ghost-background'></div>

				<div className="media-body">
					<div style={{width: 150, height: 26, marginBottom: 10}} className='ghost-background'></div>
					<div style={{width: 120, height: 24, marginBottom: 25}} className='ghost-background'></div>
					<div style={{width: 500, height: 150}} className='ghost-background'></div>
				</div>
			</div>
		)
	}
}
