import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { statusMessage } from '../components/StatusMessage';
import { popupMessage } from '../components/PopupMessage';
import * as routes from '../routes';
import AdminEditTag from './AdminEditTag';

export default class AdminTagEntry extends Component {

	constructor(props){
		super(props);

		this.state = {
			isEditingTag: false
		}
	}

	updateEditTagState = (state) => {
		this.setState({
			isEditingTag: state
		})
	}

	deleteTag = () => {
		axios.post(`${routes.DOMAIN}/v2/tags.php`, {
			action: 'fullDeleteTag',
			jwt: this.props.cookies.get('tk'),
			tag_id: this.props.tag.id,
		})
		.then((result) => {
				console.log(result);
				if(result.data == true){
					popupMessage({
						type: 'success',
						message: 'Brisanje uspešno'
					})
					this.props.refreshTags();
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri brisanju!'
					})
				}
			})
	}

	warnDelete = () => {
		statusMessage({
			title: 'Opozorilo',
			message: 'Ali ste prepričani da želite izbrisati oznako?',
			message2: `Izbrisana bo tudi z vseh treningov!`,
			buttons: [
				{
					label: 'Da',
					style: 'primary',
					onClick: () => this.deleteTag(this.props.tag.id)
				},
				{
					label: 'Ne'
				}
			]
		});
	};

	render() {

		const tag = this.props.tag;

		return (
			<tr>
				<td>{tag.title}</td>
				<td><button type='button' style={{outline: 'none'}} className='btn-checkout' onClick={() => this.updateEditTagState(true)}>Uredi</button></td>
				<td><button type='button' style={{outline: 'none'}} className='btn-accent-fill-ghost' onClick={() => this.warnDelete()}>Izbriši</button></td>
				<td>{this.state.isEditingTag ? <AdminEditTag tag={tag} {...this.props} updateEditTagState={this.updateEditTagState} refreshUsers={this.props.refreshTags} /> : null}</td>
			</tr>
		)
	}
}
