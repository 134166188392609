import React, { Component } from 'react'
import EditUser from './EditUser';

export default class UserEntry extends Component {

	constructor(props){
		super(props);
		
		this.state = {
			isEditingUser: false
		}
	}

	updateEditUserState = (state) => {
		this.setState({
			isEditingUser: state
		})
	}

	render() {

		const user = this.props.user;

		return (
			<tr>
				<td>{user.first_name}</td>
				<td>{user.last_name}</td>
				<td>{user.email}</td>
				<td>{user.activated === 1 ? 'Da' : 'Ne'}</td>
				<td>{user.access_level}</td>
				<td><button type='button' style={{outline: 'none'}} className='btn-checkout' onClick={() => this.updateEditUserState(true)}>Uredi</button></td>
				{user.id !== 1 ? <td><button type='button' style={{outline: 'none'}} className='btn-accent-fill-ghost' onClick={() => this.props.deleteUser(user.id)}>Izbriši</button></td> : null }
				<td>{this.state.isEditingUser ? <EditUser user={user} {...this.props} updateEditUserState={this.updateEditUserState} refreshUsers={this.props.refreshUsers} /> : null}</td>
			</tr>
		)
	}
}
