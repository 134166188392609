import React, { Component } from 'react'
import axios from 'axios';

import * as routes from '../routes';
import { popupMessage } from './PopupMessage.js';

export default class AdminEditTag extends Component {

	updateTag = () => {
		axios.post(`${routes.DOMAIN}/v2/tags.php`, {
			action: 'editTag',
			jwt: this.props.cookies.get('tk'),
			tag_id: this.props.tag.id,
			tag_title: this.refs.new_tag_name.value
		})
			.then((result) => {
				if(result.data == true){
					popupMessage({
						type: 'success',
						message: 'Shranjevanje uspešno'
					})
					this.props.updateEditTagState(false);
					this.props.refreshTags();
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri shranjevanju!'
					})
				}
			})
	}

	render() {

		const tag = this.props.tag;

		return (
			<div className='editUser-wrapper'>
				<div className='editUser'>
					<h3>Urejanje oznake</h3>
					<form style={{justifyItems: 'center'}} className='editUser-data'>
						<label>Naslov: </label>
						<input ref='new_tag_name' type='text' className='custom-input' defaultValue={tag.title}/>
						
						<button type='button' style={{maxWidth: 150}} className='btn-checkout' onClick={this.updateTag}>Shrani</button>
						<button type='button' style={{maxWidth: 150}} className='btn-checkout white' onClick={() => this.props.updateEditTagState(false)}>Prekliči</button>
					</form>

				</div>
			</div>
		)
	}
}
