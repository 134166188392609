import React, { Component } from "react";
import { Link } from 'react-router-dom';

import * as routes from '../routes';

export default class TopHeader extends Component {
	render() {

		const config = this.props.config;

		return(
			<div className="inner-page-banner-area title-sub" style={{ backgroundImage: 'url("/assets/img/banner.webp")', paddingBottom: 70}}>
				<div className="container">
					<div className="pagination-area">
						{config.title === '12loading12' ? <div style={{width: 200, height: 48, margin: '0 auto', opacity: .4}} className='ghost-background'></div> : <h2 className="inner-section-title-textprimary">{config.title}</h2>}

						<ul>
							<li>
								<Link to='/'>Domov &nbsp;-&nbsp;</Link>
							</li>
							{config.pages ? config.pages.map((page, index) => {
								return(
									<li key={index}><Link to={`${routes[page.link]}`}>{page.name} &nbsp;-&nbsp;</Link></li>
								)
							}) : null}
							<li>{config.title === '12loading12' ? <div style={{width: 70, height: 19, margin: '0 auto', opacity: .8}} className='ghost-background'></div> : config.title}</li>
						</ul>
						
						{config.title == 'Naši Treningi' ? (
						<div style={{display: 'flex', justifyContent: 'center', marginTop: 14}}>
							<Link to={routes.addTraining} className='btn-checkout'>Nov trening</Link>
						</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}
