import React, { Component } from "react";
import { HashLink } from 'react-router-hash-link';
export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="footer-area-top accent-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="footer-box">
                  <h3 className="title-bar-footer">Kontakt</h3>
                  <ul className="corporate-address">
                    <li>
                      <i className="fa fa-phone" aria-hidden="true" />
                      <p>Telefon: (+386) 041 764 321</p>
					  <p>Fax: /</p>
                      
                    </li>
                    <li>
                      <i className="fa fa-envelope-o" aria-hidden="true" />
                      <p>mito@sinkovec.si</p>
                      <p>info@telodrom.com</p>
                    </li>
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />
                      <p>Telodrom, studio za oblikovanje in kultivacijo telesa</p>
                      <p>Kolarjeva 45a</p>
                      <p>1000, Ljubljana</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="footer-box">
                  <h3 className="title-bar-footer">Uporabne povezave</h3>
                  <ul className="useful-links">
                    <li>
                      <ul>
                        <li>
                          <a href="https://mito.si/" target="_blank" rel="noopener noreferrer">Mito Šinkovec</a>
                        </li>
                        <li>
                          <a href="http://www.telodrom.com/sl/" target="_blank" rel="noopener noreferrer">Telodrom</a>
                        </li>
                        
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

			  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
					<div className="footer-box">
						<h3 className="title-bar-footer">Hitra navigacija</h3>
						<ul className="useful-links">
							<li>
								<ul>
									<li>
										<HashLink to='/#top' smooth>Domov</HashLink>
									</li>

									<li>
										<HashLink to='/#top' smooth>Treningi</HashLink>
									</li>

									<li>
										<HashLink to='#' smooth>Kontakt</HashLink>
									</li>

								</ul>
							</li>
						</ul>
					</div>
				</div>
              
              
            </div>
          </div>
        </div>
        <div className="footer-area-bottom accent-bg-light">
          <div className="container">
            <p>
              @2019 All rights reserved. 
              <a href="https://mito.si"> Mito</a>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
