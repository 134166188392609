import React, { Component } from 'react';
import axios from 'axios';
import Textarea from 'react-textarea-autosize';
import {isSafari} from 'react-device-detect';

import * as routes from '../routes';
import { popupMessage } from './PopupMessage';
import ProgressBar from './ProgressBar';

export default class AddNote extends Component {

	constructor(props){
		super(props);

		this.state = {
			note_picture_url: ''
		}
	}

	handleAddNote = () => {
		let formData = new FormData();

		formData.append('action', 'addNote');
		formData.append('environment', process.env.NODE_ENV);
		formData.append('nodecode', true);
		formData.append('jwt', this.props.cookies.get('tk'));
		formData.append('training_id',  this.props.match.params.id);
		formData.append('note_title',  this.refs.note_title.value);
		formData.append('note_content',  this.note_content.value);
		formData.append('note_image_url',  this.state.note_picture_url);

		
		axios.post(`${routes.DOMAIN}/v2/notes.php`, formData)
			.then((result) => {
				//console.log(result);
				if(result.data == true){
					this.props.refreshNotes(false, true);
					popupMessage({
						type: 'success',
						message: 'Opomba uspešno dodana.'
					})
				}else{
					popupMessage({
						type: 'error',
						message: 'Napaka pri dodajanju opombe!'
					})
				}
			})
	}

	uploadImage = () => {
		var imageName = `note_picture_url`;
		var errorName = `note_picture_error`;
		var uploadProgress = `note_picture_progress`;
		var totalSize = `note_picture_size`;
		let formData = new FormData();

		formData.append('action', 'uploadImage');
		formData.append('environment', process.env.NODE_ENV);
		formData.append('nodecode', true);
		formData.append('jwt', this.props.cookies.get('tk'));
		formData.append(`new_image`, this.refs.note_picture.files[0]);

		const config = {
			onUploadProgress: (progressEvent) => {
				this.setState({
					[uploadProgress]: progressEvent.loaded,
					[totalSize]: progressEvent.total
				})
			}
		}

		axios.post(`${routes.DOMAIN}/v2/notes.php`, formData, config)
		.then((result) => {
			//console.log(result.data);
			if((result.data).startsWith("/assets/img")){
				this.setState({
					[imageName]: result.data,
					[errorName]: null
				})
			}else{
				this.setState({
					[imageName]: null,
					[errorName]: result.data
				})
				popupMessage({
					type: 'error',
					message: 'Napaka pri nalaganju slike!'
				})
			}
		})
	}
	
	render() {	
		return (	
			<form className='noteWrapper'>
				<p className='login-input-title'>Naslov</p>
				<input ref='note_title' type='text' className='custom-input' /><br/>
				<p className='login-input-title'>Slika</p>
				<input ref='note_picture' type="file" id='note_picture' style={{display: 'none'}} onInput={() => this.uploadImage()} onChange={() => isSafari ? this.uploadImage() : null}/>
				<label htmlFor='note_picture' style={{backgroundImage: `url('${this.state.note_picture_url ? this.state.note_picture_url : "none"}')`}} className='image-upload-area'>
					<div className={"image-upload-area-inner " + (this.state.note_picture_url ? "change_message" : null)}>
						<React.Fragment>
							<span><svg xmlns="http://www.w3.org/2000/svg" className='image-icon' viewBox="0 0 32 32" version="1.1" width="64px" height="64px"><g id="surface1"><path d="M 2 5 L 2 27 L 30 27 L 30 5 Z M 4 7 L 28 7 L 28 20.90625 L 22.71875 15.59375 L 22 14.875 L 17.46875 19.40625 L 11.71875 13.59375 L 11 12.875 L 4 19.875 Z M 24 9 C 22.894531 9 22 9.894531 22 11 C 22 12.105469 22.894531 13 24 13 C 25.105469 13 26 12.105469 26 11 C 26 9.894531 25.105469 9 24 9 Z M 	11 15.71875 L 20.1875 25 L 4 25 L 4 22.71875 Z M 22 17.71875 L 28 23.71875 L 28 25 L 23.03125 25 L 18.875 20.8125 Z "/></g></svg></span>
							{this.state.note_picture_error !== "Izberite sliko" ? <span>{this.state.note_picture_url ? "Zamenjaj sliko" : "Naloži sliko"}</span> : null}
						</React.Fragment>
						{this.state.note_picture_error ? <span style={{color: '#e80000'}}>{this.state.note_picture_error}</span> : null}
					</div>
				</label>
				<ProgressBar progress={this.state.note_picture_progress} size={this.state.note_picture_size} error={this.state.note_picture_error}/>

				<p className='login-input-title'>Opis</p>
				<Textarea inputRef={textarea => (this.note_content = textarea)} type='text' style={{width: '100%', transition: 'none', minHeight: 120}} className='custom-input'></Textarea><br/>
				<div className='noteButtonsWrapper'>
					<button type='button' className='btn-checkout' onClick={() => this.handleAddNote()}>Shrani</button>
					<button type='button' className='btn-accent-fill-ghost' onClick={() => this.props.refreshNotes(false)}>Prekliči</button>
				</div>
			</form>
		)
	
	}
}
